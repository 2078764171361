import React, {
  createRef,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as Styled from './styled'
import { IconName } from '../icon'

interface NavTabProps<ID> {
  tabsToRender: { id: ID; title: string | ReactNode; icon?: IconName }[]
  positionsToRender?: number[][]
  selectedTab: ID
  onSelectTab: (tab: ID) => void
  className?: string
}

export const NavTab: <T>(props: NavTabProps<T>) => ReactElement = ({
  tabsToRender,
  positionsToRender,
  selectedTab,
  onSelectTab,
  className,
}) => {
  const tabRefs = useRef<RefObject<HTMLDivElement>[]>([])
  const [positions, setPositions] = useState<{
    offsetLeft?: number
    offsetTop?: number
    offsetWidth?: number
    offsetHeight?: number
  }>({})

  tabRefs.current = tabsToRender.map(
    (tab, index) => (tabRefs.current[index] = createRef())
  )

  const selectedIndex = tabsToRender.findIndex(
    (item) => item.id === selectedTab
  )

  useEffect(() => {
    const refElement = tabRefs.current[selectedIndex].current

    if (refElement !== null) {
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = refElement
      setPositions({ offsetLeft, offsetTop, offsetWidth, offsetHeight })
    }
  }, [selectedIndex])

  const tabsPositions = positionsToRender
    ? positionsToRender
    : [tabsToRender.map((_, index) => index)]

  return (
    <Styled.Root className={className}>
      <Styled.NavTabSelectedUnderlayer
        $offsetLeft={positions.offsetLeft}
        $offsetTop={positions.offsetTop}
        $offsetWidth={positions.offsetWidth}
        $offsetHeight={positions.offsetHeight}
      />

      {tabsPositions.map((tabPosition, index) => (
        <Styled.NavTabWrapper key={index}>
          {tabPosition.map((itemIndex, index) => {
            const tab = tabsToRender[itemIndex]

            return (
              <Styled.NavTab
                $isSelected={selectedTab === tab.id}
                ref={tabRefs.current[itemIndex]}
                key={`${tab.title}_${index}`}
                onClick={() => onSelectTab(tab.id)}
              >
                {tab.icon && <Styled.NavTabIcon name={tab.icon} />}

                {typeof tab.title === 'string' ? (
                  <Styled.NavTabText>{tab.title}</Styled.NavTabText>
                ) : (
                  tab.title
                )}
              </Styled.NavTab>
            )
          })}
        </Styled.NavTabWrapper>
      ))}
    </Styled.Root>
  )
}
