import { useState, useEffect, useCallback, ReactNode } from 'react'
import { throttle } from 'lodash'

interface MediaProps {
  xs?: boolean // < 375
  s?: boolean // >= 375 & < 768
  mobile?: boolean // < 768
  tablet?: boolean // >=768 & < 1024
  desktop?: boolean // >= 1024
  l?: boolean // >= 1024 & < 1280
  xl?: boolean // >= 1280
  children: ReactNode
}

export const Media = ({
  xs,
  s,
  mobile,
  tablet,
  desktop,
  l,
  xl,
  children,
}: MediaProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = useCallback(
    throttle(() => {
      setWindowWidth(window.innerWidth)
    }, 300),
    []
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  if (windowWidth < 375 && xs) {
    return <>{children}</>
  } else if (windowWidth >= 376 && windowWidth < 768 && s) {
    return <>{children}</>
  } else if (windowWidth < 768 && mobile) {
    return <>{children}</>
  } else if (windowWidth >= 768 && windowWidth < 1024 && tablet) {
    return <>{children}</>
  } else if (windowWidth >= 1024 && desktop) {
    return <>{children}</>
  } else if (windowWidth >= 1024 && windowWidth < 1280 && l) {
    return <>{children}</>
  } else if (windowWidth >= 1280 && xl) {
    return <>{children}</>
  }

  return null
}
