import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { GlobalStyles } from './styled'
import { Router } from '@/0_app/router'
import { ToastContainer } from 'react-toastify'
import { ToastifyStyles } from '@/7_services/notification-service'
import './lang'

import { attachReduxDevTools } from '@effector/redux-devtools-adapter'

attachReduxDevTools()

const root = createRoot(document.getElementById('root') as HTMLElement)
const App = () => {
  return (
    <React.StrictMode>
      <Suspense fallback="loading">
        <GlobalStyles />
        <Router />
        <ToastContainer theme="dark" autoClose={5000} />
        <ToastifyStyles />
      </Suspense>
    </React.StrictMode>
  )
}
root.render(<App />)
