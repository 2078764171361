import React, { FC, memo } from 'react'
import * as Styled from './styled'

type EmailInputProps = React.InputHTMLAttributes<HTMLInputElement>

export const EmailInput: FC<EmailInputProps> = memo(
  (props: EmailInputProps) => {
    return <Styled.Root {...props} />
  }
)

EmailInput.displayName = 'EmailInput'
