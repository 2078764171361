import type { Store } from 'effector'

export class MainService<T extends Store<unknown>> {
  _store: T
  _state: unknown

  constructor(store: T) {
    this._store = store

    store.watch((state) => {
      this._state = state
    })
  }

  bindListeners = () => {
    throw new Error('You must specify bindListeners method in your class')
  }

  getStore(): T {
    return this._store
  }

  getState() {
    return this._state
  }
}
