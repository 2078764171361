import { domain } from './domain'
import {
  checkTermCheckbox,
  closeAuthModal,
  closeAuthModalEvent,
  login,
  nicknameChangeEvent,
  openAuthModal,
  passwordChangeEvent,
  confirmPasswordChangeEvent,
  phoneChangeEvent,
  selectTab,
  serialChangeEvent,
  signUp,
  codeChangeEvent,
} from './events'
import { AuthTab } from '@/2_widgets/auth-modal/ui/auth-modal'
import { combine, guard, sample } from 'effector'
import { UserService } from '@/7_services/user'

export const $isAuthModalOpen = domain
  .createStore<boolean>(false)
  .on(openAuthModal, () => true)
  .reset(closeAuthModalEvent)
  .reset(UserService.loginFx.doneData)

export const $phoneInput = domain
  .createStore<string>('')
  .on(phoneChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $selectedAuthTab = domain
  .createStore<AuthTab>(AuthTab.Login)
  .on(selectTab, (_, payload) => payload)

export const $isVerifyRequest = domain
  .createStore<boolean>(false)
  .on(UserService.registrationRequest, () => true)
  .on(UserService.passwordReset, () => true)
  .reset(closeAuthModalEvent)
  .reset(selectTab)

guard({
  clock: closeAuthModal,
  source: UserService.getStore(),
  filter: ({ isSignUpPromoPending }) => !isSignUpPromoPending,
  target: closeAuthModalEvent,
})

export const $nicknameInput = domain
  .createStore<string>('')
  .on(nicknameChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $passwordInput = domain
  .createStore<string>('')
  .on(passwordChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $confirmPasswordInput = domain
  .createStore<string>('')
  .on(confirmPasswordChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $serialInput = domain
  .createStore<string>('')
  .on(serialChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $checkboxTermsChecked = domain
  .createStore(false)
  .on(checkTermCheckbox, (store) => !store)
  .reset(closeAuthModalEvent)

export const $codeInput = domain
  .createStore<string>('')
  .on(codeChangeEvent, (_, payload) => payload)
  .reset(closeAuthModalEvent)

export const $isVerifyCodeButtonDisabled = combine(
  $codeInput,
  $phoneInput,
  $passwordInput,
  $confirmPasswordInput,
  (code, phone, password, confirmPassword) =>
    code.length === 0 ||
    phone.length === 0 ||
    password.length === 0 ||
    confirmPassword.length === 0 ||
    confirmPassword !== password
)

export const $isSendPhoneButtonDisabled = combine(
  $phoneInput,
  (phone) => phone.length === 0
)

export const $isSignUpButtonDisabled = combine(
  $checkboxTermsChecked,
  UserService.getStore(),
  $phoneInput,
  $passwordInput,
  $confirmPasswordInput,
  (checked, { isSignUpPromoPending }, phone, password, confirmPassword) => ({
    checked,
    pending: isSignUpPromoPending,
    phone,
    password,
    confirmPassword,
  })
).map(({ checked, pending, phone, password, confirmPassword }) => {
  return (
    !checked ||
    pending ||
    phone.length === 0 ||
    password.length === 0 ||
    confirmPassword.length === 0 ||
    confirmPassword !== password
  )
})

sample({
  clock: login,
  source: combine($phoneInput, $passwordInput, (phone, password) => ({
    login: `+${phone}`,
    password,
  })),
  target: UserService.login,
})

export const data = combine({
  phoneInput: $phoneInput,
  passwordInput: $passwordInput,
})

sample({
  clock: UserService.codeVerifyFx.done,
  source: data,
  fn: (data) => ({
    login: `+${data.phoneInput}`,
    password: data.passwordInput,
  }),
  target: UserService.login,
})

sample({
  clock: signUp,
  source: combine(
    $phoneInput,
    $passwordInput,
    $serialInput,
    (phone, password, serial) => {
      const data: {
        user_login: string
        user_password: string
        promo_code?: number
      } = {
        user_login: `+${phone}`,
        user_password: password,
      }
      if (serial) {
        data.promo_code = Number(serial)
      }
      return data
    }
  ),
  target: UserService.signUpPromo,
})

// forward({
//   from: login,
//   to: UserService.login,
// })
