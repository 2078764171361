import styled, { css } from 'styled-components'
import { defaultColorPalette, MediaMaxS } from '@/5_shared/styles'
import { Input as InputUi, LabeledInput } from '@/5_shared/ui'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 72px;
`

export const CurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
`

const blockStyles = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -24px;
`

export const AvailableBlock = styled.div`
  ${blockStyles}
`
export const NonAvailableBlock = styled.div`
  ${blockStyles};

  opacity: 0.5;
`

export const SelectCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const SelectCurrencyTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${defaultColorPalette.darkTypeHigh};
`
export const TextBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const QrcodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectCurrencyText = styled.div`
  color: ${defaultColorPalette.darkTypeMedium};
`

export const NonAvailableCurrencyWrapper = styled.div`
  flex-basis: calc(33% - 24px);
  height: 84px;

  ${MediaMaxS`
    flex-basis: calc(50% - 16px);
  `}
`

export const CurrencyCardWrapper = styled.div`
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: calc(50% - 16px);
  `}
`

export const CurrencyStepHeadBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  ${MediaMaxS`
    flex-direction: column;
  `}
`

export const CurrencyMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`

export const Labeled = styled(LabeledInput)`
  position: relative;
`

export const Input = styled(InputUi)``

export const CurrencyStepSelectCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-basis: calc(50% - 12px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const CurrencyTabCurrencyCardWrapper = styled.div`
  flex-basis: calc(50% - 12px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const Copy = styled.div`
  width: 50px;
  text-align: center;
`
