import React, { FC, memo, ReactNode } from 'react'
import * as Styled from './styled'

interface ModalPaperProps {
  children: ReactNode
  renderCloseCross: boolean
  onClose: () => void
}

export const ModalPaper: FC<ModalPaperProps> = memo(
  ({ children, renderCloseCross, onClose }: ModalPaperProps) => {
    return (
      <Styled.ModalPaper>
        {renderCloseCross && (
          <Styled.CloseCross onClick={onClose}>
            <Styled.CloseIcon />
          </Styled.CloseCross>
        )}
        {children}
      </Styled.ModalPaper>
    )
  }
)

ModalPaper.displayName = 'ModalPaper'
