import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { Currency } from '../model'

interface CurrencyCardProps {
  onClick?: () => void
  isSelected?: boolean
  disabled?: boolean
  amount?: number
  currency: Currency
}

export const CurrencyCard: FC<CurrencyCardProps> = memo(
  ({ amount, currency, isSelected, disabled, onClick }: CurrencyCardProps) => {
    return (
      <Styled.Root
        onClick={disabled ? () => { } : onClick}
        $isSelected={isSelected}
        $isDisabled={disabled}
      >
        {!disabled && amount != null && (
          <Styled.Header $isSelected={isSelected}>{amount}</Styled.Header>
        )}

        <Styled.CurrencyInfo>
          <Styled.CurrencyLogo $currency={currency?.ShortName} />

          <Styled.NameBlock>
            <Styled.Name>{currency?.ShortName}</Styled.Name>
            <Styled.Description>{currency?.Name}</Styled.Description>
          </Styled.NameBlock>
        </Styled.CurrencyInfo>
      </Styled.Root>
    )
  }
)

CurrencyCard.displayName = 'CurrencyCard'
