import { FC, useEffect, useState } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import { $filteredAndSelectedGames, $loading } from '../model'
import { UserService } from '@/7_services/user'
import { Game, GamesService } from '@/7_services/games'
import { GameCard, GameCardSkeleton } from '@/2_widgets/game-card'
import { selectGame } from '@/7_services/games/model/events'
import { openAuthModal } from '@/2_widgets/auth-modal/model'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'

export const FilteredList: FC = () => {
  const data = useStore($filteredAndSelectedGames)
  const loading = useStore($loading)
  const { isLoggedIn } = useStore(UserService.getStore())
  const { baseIconUrl } = useStore(GamesService.getStore())
  const [items, setItems] = useState<Game[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    if (!loading && data.length) {
      setItems(data.slice(0, 9))
    }
  }, [data, loading])
  return (
    <>
      {loading && (
        <Styled.GamesList>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <Styled.GameCardWrapper key={item}>
              <GameCardSkeleton />
            </Styled.GameCardWrapper>
          ))}
        </Styled.GamesList>
      )}
      {!loading && data.length !== 0 && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={() => setItems(data.slice(0, items.length + 8))}
          hasMore={true}
          loader={<span />}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
          // below props only if you need pull down functionality
          // refreshFunction={this.refresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>
          //     &#8595; Pull down to refresh
          //   </h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
        >
          <Styled.GamesList>
            {items.map((card: Game) => (
              <Styled.GameCardWrapper
                key={card.ID}
                onClick={() => {
                  isLoggedIn ? selectGame(card.ID) : openAuthModal()
                }}
              >
                <GameCard {...card} baseIconUrl={baseIconUrl} />
              </Styled.GameCardWrapper>
            ))}
          </Styled.GamesList>
        </InfiniteScroll>
      )}
      {/* {!loading &&
        data.length !== 0 &&
        data.map((card) => (
          <Styled.GameCardWrapper
            key={card.ID}
            onClick={() => {
              isLoggedIn ? selectGame(card.ID) : openAuthModal()
            }}
          >
            <GameCard {...card} baseIconUrl={baseIconUrl} />
          </Styled.GameCardWrapper>
        ))} */}
      {!loading && data.length === 0 && (
        <Styled.GamesList>
          <Styled.NoResults>{t('noResults')}</Styled.NoResults>
        </Styled.GamesList>
      )}
    </>
  )
}

FilteredList.displayName = 'FilteredList'
