import React, { FC, memo } from 'react'
import * as Styled from './styled'

type PasswordInputProps = React.InputHTMLAttributes<HTMLInputElement>

export const PasswordInput: FC<PasswordInputProps> = memo(
  (props: PasswordInputProps) => {
    return <Styled.Root {...props} />
  }
)

PasswordInput.displayName = 'PasswordInput'
