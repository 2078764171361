import { Languages } from '@/5_shared/localized-message'

export const availableLanguages = [
  {
    label: 'English',
    value: Languages.English,
  },
  // {
  //   label: 'Polski',
  //   value: Languages.Polish,
  // },
  // {
  //   label: '中文',
  //   value: Languages.Chinese,
  // },
  // {
  //   label: '日本語',
  //   value: Languages.Japanese,
  // },
  // {
  //   label: 'Deutsch',
  //   value: Languages.German,
  // },
  // {
  //   label: 'Français',
  //   value: Languages.French,
  // },
  // {
  //   label: 'Español',
  //   value: Languages.Spanish,
  // },
  // {
  //   label: 'Português',
  //   value: Languages.Portugal,
  // },
  {
    label: 'Русский',
    value: Languages.Russian,
  },
  {
    label: 'Shqip',
    value: Languages.Albanian,
  },
  {
    label: 'Greek',
    value: Languages.Greek,
  },
]
