import styled from 'styled-components'
import { BASE_Z_INDEX, defaultColorPalette, MediaMaxM } from '@/5_shared/styles'
import { LoginButton as LoginButtonUi } from '@/2_widgets/auth-modal'
import { ProfileBlock as ProfileBlockUi } from '@/2_widgets/profile-block'
import { Link } from 'react-router-dom'
import { LogoImage } from '@/5_shared/assets'
import { Jackpots } from '@/2_widgets/jackpots'

export const Root = styled.div`
  max-width: 100vw;
  min-width: 375px;
  position: relative;
`

export const Modals = styled.div`
  position: absolute;
`

export const MainLayoutDesktop = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: stretch;
  overscroll-behavior: auto;
`

export const MainLayoutMobile = styled.div`
  display: flex;
  flex-direction: column;
  overscroll-behavior: auto;
`

export const MainBlock = styled.div``

export const MainSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${defaultColorPalette.darkSurfaceTertiary};
  z-index: ${BASE_Z_INDEX.MAIN_SIDEBAR};
`

export const SidebarWrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 57px;
  min-width: 354px;

  ${MediaMaxM`
    display: none
  `}
`

export const LoginButton = styled(LoginButtonUi)`
  width: 250px;
`

export const ProfileBlock = styled(ProfileBlockUi)``

export const LogoLink = styled(Link)`
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 0;
  line-height: 0;
`

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  width: 250px;
  height: 194px;
  background-color: ${defaultColorPalette.darkPrimary700};
`

export const JackpotsMobile = styled(Jackpots)`
  margin-bottom: 42px;
`

export const ChildrenWrapper = styled.div`
  flex-grow: 1;
`
