import styled from 'styled-components'
import {
  Button,
  ButtonSize,
  Icon,
  IconName,
  Input as InputUi,
  LabeledInput,
} from '@/5_shared/ui'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxS,
  MediaMaxXs,
} from '@/5_shared/styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px 12px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  ${MediaMaxS`
    gap: 12px;
  `}
`

export const Labeled = styled(LabeledInput)`
  flex-basis: calc(50% - 6px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const Input = styled(InputUi)``

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const ProfileCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${defaultColorPalette.darkPrimary700};
  border: 1px solid ${defaultColorPalette.darkNeutrals300};
  color: ${defaultColorPalette.white};
`

export const ProfileCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ProfileCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

export const ProfileAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${defaultColorPalette.darkPrimary800};
  cursor: pointer;
  transition: all ${defaultTransitionTiming.fastest}s ease;

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary900};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary1000};
  }

  ${MediaMaxS`
    width: 42px;
    height: 42px;
  `}
`

export const ProfileAvatarIcon = styled(Icon).attrs({
  name: IconName.UserProfile03,
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})``

export const ProfilePhone = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  white-space: nowrap;

  ${MediaMaxS`
    font-size: 16px;
  `}
`

export const ProfileOffer = styled.div``

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Balance = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const BalanceAmount = styled.div`
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;

  ${MediaMaxS`
    font-size: 32px;
  `}

  ${MediaMaxXs`
    font-size: 24px;
  `}
`

export const OnBalance = styled.div`
  ${MediaMaxXs`
    font-size: 14px;
  `}
`

export const TopUpButton = styled(Button).attrs({
  size: ButtonSize.Xs,
})``
