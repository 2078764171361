import * as api from '../api'
import { domain } from './domain'
import { Event } from 'effector'

export const login: Event<{ login: string; password: string }> =
  domain.createEvent('try to login')

export const loginFx = domain.createEffect('login', {
  handler: api.login,
})

export const logout = domain.createEvent('logout')

export const signUp = domain.createEvent<{ phone: string }>('try to sign up')

export const signUpFx = domain.createEffect<{ phone: string }, {}>('sign up', {
  handler: api.signUp,
})

export const signUpPhone = domain.createEvent<{
  user_login: string
  user_password: string
  serial: number
  gh_id: number
}>('try to sign up phone')

export const signUpPhoneFx = domain.createEffect<
  {
    user_login: string
    user_password: string
    serial: number
    gh_id: number
  },
  {}
>('sign up phone', {
  handler: api.signUpPhone,
})

export const signUpPromo = domain.createEvent<{
  user_login: string
  user_password: string
  promo_code: number
  gh_id: number
}>('try to sign up phone with promo')

export const signUpPromoFx = domain.createEffect<
  {
    user_login: string
    user_password: string
    promo_code: number
    gh_id: number
  },
  {}
>('sign up phone with promo', {
  handler: api.signUpPromo,
})

export const codeVerify = domain.createEvent<{
  user_login: string
  verify_code: string
}>('try to code verify')

export const codeVerifyFx = domain.createEffect<
  {
    user_login: string
    verify_code: string
  },
  {}
>('code verify', {
  handler: api.codeVerify,
})

export const passwordReset = domain.createEvent<{
  user_login: string
}>('try to reset password')

export const passwordResetFx = domain.createEffect<
  {
    user_login: string
  },
  {}
>('reset password', {
  handler: api.passwordReset,
})

export const passwordResetVerify = domain.createEvent<{
  user_login: string
  verify_code: string
  new_password: string
}>('try to reset password verify')

export const passwordResetVerifyFx = domain.createEffect<
  {
    user_login: string
    verify_code: string
    new_password: string
  },
  {}
>('reset password verify', {
  handler: api.passwordResetVerify,
})

export const registrationRequest = domain.createEvent(
  'TRY TO REGISTRATION REQUEST'
)
