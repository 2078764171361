import { FC, memo } from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'

type HeadedListProps = {
  title: string
  onClick: (name: string | null) => void
  selected: string | null
  options: { [key: string]: number }
  className?: string
  totalAmount: number
}

export const HeadedList: FC<HeadedListProps> = memo(
  ({
    title,
    options,
    onClick,
    selected,
    className,
    totalAmount,
  }: HeadedListProps) => {
    const { t } = useTranslation()
    return (
      <Styled.HeadedListWrapper className={className}>
        <Styled.ListHeader>{title}</Styled.ListHeader>
        <Styled.ListItem
          $isSelected={selected === null}
          onClick={() => onClick(null)}
        >
          {t('all')}
          <Styled.ListAmount>{totalAmount}</Styled.ListAmount>
        </Styled.ListItem>

        <Styled.List>
          {Object.keys(options)
            .sort()
            .map((optionName) => (
              <Styled.ListItem
                onClick={() => onClick(optionName)}
                key={optionName}
                $isSelected={selected === optionName}
              >
                {optionName}
                <Styled.ListAmount>{options[optionName]}</Styled.ListAmount>
              </Styled.ListItem>
            ))}
        </Styled.List>
      </Styled.HeadedListWrapper>
    )
  }
)

HeadedList.displayName = 'HeadedList'
