import { FC, useRef, useEffect, useState } from 'react'
import * as Styled from './styled'
import { FilteredList } from './filtered-list'
import { FilteredGamesListGate } from '../model'
import { GamesService } from '@/7_services/games'
import { UserService } from '@/7_services/user'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { getGameById } from '@/7_services/games/model/events'
import IFrame from './iFrame'

export const FilteredGamesList: FC = () => {
  const { t } = useTranslation()
  const { selectedGame, actualGame, gamesList } = useStore(
    GamesService.getStore()
  )
  const { user } = useStore(UserService.getStore())
  const gameContainer = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    if (selectedGame) {
      getGameById({
        width: window.innerWidth * window.devicePixelRatio,
        height: window.innerHeight * window.devicePixelRatio,
        id: Number(selectedGame),
        denomination: 0.01,
        currencyId: user?.currency_id ? user?.currency_id : 49,
      })
      document.body.style.overflow = 'hidden'
      const chatContainer = document.getElementById('lhc_container_v2')
      if (chatContainer) chatContainer.style.display = 'none'
    } else {
      document.body.style.overflow = 'auto'
      const chatContainer = document.getElementById('lhc_container_v2')
      if (chatContainer) chatContainer.style.display = 'block'
    }
  }, [gamesList, selectedGame, user?.currency_id])

  useEffect(() => {
    if (selectedGame) {
      setScrollPosition(window.scrollY)
    } else {
      window.scrollTo(0, scrollPosition)
    }
  }, [scrollPosition, selectedGame])

  return (
    <Styled.Root ref={gameContainer}>
      <FilteredGamesListGate />
      <>
        <Styled.HeadlineBlock>
          <Styled.Title>{t('allGames')}</Styled.Title>
          <Styled.SearchInput />
        </Styled.HeadlineBlock>

        {selectedGame && actualGame && <IFrame />}
        <FilteredList />
      </>
    </Styled.Root>
  )
}

FilteredGamesList.displayName = 'FilteredGamesList'
