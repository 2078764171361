export const enum ButtonVariant {
  Standard = 'standard',
  Inverted = 'inverted',
}

export const enum ButtonType {
  Primary = 'primary',
  Neutral = 'neutral',
}

export const enum ButtonSize {
  Xs = 'extra-small',
  S = 'small',
  M = 'medium',
  L = 'large',
  Xl = 'extra-large',
}
