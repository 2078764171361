import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import { ContextProvider } from '@/0_app/context-provider'
import { MainPage } from '@/1_pages/main'
import { TermsPage } from '@/1_pages/terms'

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ContextProvider />}>
      <Route index element={<MainPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/cooperation" element={<TermsPage />} />
    </Route>
  )
)

export { routes }
