import styled from 'styled-components'

export const SvgWrapper = styled.div<{ color?: string; fill?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg path {
    stroke: ${({ color }) => (color ? color : 'auto')};
    fill: ${({ fill }) => (fill ? fill : 'auto')};
  }
`
