import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { CurrencyCard } from '@/4_entities/currency/ui'
import { Button, ButtonType, ButtonVariant } from '@/5_shared/ui'
import { closeProfileModal, setWithdrawCurrency } from '../../../model'
import { useStore } from 'effector-react'
import {
  $fetchedBalancesList,
  $fetchedPaymentSystems,
} from '@/2_widgets/profile-block'
import { useTranslation } from 'react-i18next'
import { UserService } from '@/7_services/user'
import { Currency } from '@/4_entities/currency'

const handleSetWithdrawCurrency = (cur: number) => {
  setWithdrawCurrency(cur)
}

const handleCloseModal = () => {
  closeProfileModal()
}

export const MainStep: FC = memo(() => {
  const balances = useStore($fetchedBalancesList)
  const paymentSystems = useStore($fetchedPaymentSystems)
  const { t } = useTranslation()
  const { user } = useStore(UserService.getStore())

  return (
    <Styled.Root>
      <Styled.CurrenciesWrapper>
        <Styled.AvailableBlock>
          <Styled.SelectCurrencyWrapper>
            <Styled.SelectCurrencyTitle>
              {t('topUpTab.available')}
            </Styled.SelectCurrencyTitle>
            <Styled.SelectCurrencyText>
              {t('withdrawTab.mainLabel')}
            </Styled.SelectCurrencyText>
          </Styled.SelectCurrencyWrapper>

          {paymentSystems.map((paymentSystem) => {
            const currency = user?.currency_list.find(
              (cur: Currency) =>
                cur.CurrencyID === paymentSystem.system_currency
            )
            if (currency) {
              return (
                <Styled.CurrencyCardWrapper key={paymentSystem.system_currency}>
                  <Styled.CurrencyCardWrapper>
                    <CurrencyCard
                      onClick={() =>
                        handleSetWithdrawCurrency(paymentSystem.system_currency)
                      }
                      currency={currency}
                      amount={
                        balances.find(
                          (balance) =>
                            balance?.currency_id ===
                            paymentSystem.system_currency
                        )?.balance
                      }
                    />
                  </Styled.CurrencyCardWrapper>
                </Styled.CurrencyCardWrapper>
              )
            }
            return null
          })}
        </Styled.AvailableBlock>
        {/* <Styled.NonAvailableBlock>
          <Styled.SelectCurrencyWrapper>
            <Styled.SelectCurrencyTitle>Waiting...</Styled.SelectCurrencyTitle>
            <Styled.SelectCurrencyText>
              Will be available in the future
            </Styled.SelectCurrencyText>
          </Styled.SelectCurrencyWrapper>
          {nonAvailableCurrencies.map((cur) => {
            return (
              <Styled.NonAvailableCurrencyWrapper key={cur.CurrencyID}>
                <CurrencyCard currency={cur.currency} disabled />
              </Styled.NonAvailableCurrencyWrapper>
            )
          })}
        </Styled.NonAvailableBlock> */}
      </Styled.CurrenciesWrapper>

      <Button
        type={ButtonType.Neutral}
        variant={ButtonVariant.Inverted}
        fluid
        onClick={handleCloseModal}
      >
        {t('buttons.close')}
      </Button>
    </Styled.Root>
  )
})

MainStep.displayName = 'MainStep'
