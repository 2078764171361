import { ChangeEvent, FC, memo } from 'react'
import * as Styled from './styled'
import { Button, ButtonType, ButtonVariant, PasswordInput } from '@/5_shared/ui'
import {
  closeProfileModal,
  // $confirmPasswordInput,
  // $passwordInput,
  // confirmPasswordChangeEvent,
  // passwordChangeEvent,
  // oldPasswordChangeEvent,
  // savePassword,
  // $oldPasswordInput,
} from '../../../model'
import { ProfileCard } from './profile-card'
// import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'

interface ProfileTabProps {}

const handleCloseModal = () => {
  closeProfileModal()
}

// const handleOldPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
//   oldPasswordChangeEvent(event.target.value)
// }

// const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
//   passwordChangeEvent(event.target.value)
// }

// const confirmPasswordInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
//   confirmPasswordChangeEvent(event.target.value)
// }
// const handleSaveNewPassword = () => {
//   savePassword()
// }

export const ProfileTab: FC<ProfileTabProps> = memo(({}: ProfileTabProps) => {
  // const password = useStore($passwordInput)
  // const confirmPassword = useStore($confirmPasswordInput)
  // const oldPassword = useStore($oldPasswordInput)
  const { t } = useTranslation()
  return (
    <Styled.Root>
      <ProfileCard />

      {/* <Styled.InfoWrapper>
        <Styled.Labeled label={t('profileTab.oldPassword')}>
          <PasswordInput
            onChange={handleOldPasswordChange}
            value={oldPassword}
          />
        </Styled.Labeled>
      </Styled.InfoWrapper>

      <Styled.InfoWrapper>
        <Styled.Labeled label={t('profileTab.newPassword')}>
          <PasswordInput onChange={handlePasswordChange} value={password} />
        </Styled.Labeled>

        <Styled.Labeled label={t('profileTab.confirmPassword')}>
          <PasswordInput
            value={confirmPassword}
            onChange={confirmPasswordInputHandler}
          />
        </Styled.Labeled>
  </Styled.InfoWrapper>*/}

      <Styled.ButtonsWrapper>
        <Button
          type={ButtonType.Neutral}
          variant={ButtonVariant.Inverted}
          fluid
          onClick={handleCloseModal}
        >
          {t('buttons.close')}
        </Button>
        {/* <Button onClick={handleSaveNewPassword} fluid>
          {t('buttons.save')}
        </Button> */}
      </Styled.ButtonsWrapper>
    </Styled.Root>
  )
})

ProfileTab.displayName = 'ProfileTab'
