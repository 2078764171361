import styled, { css, keyframes } from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxS,
  MediaMaxXs,
} from '@/5_shared/styles'
import ADALogo from './assets/ada.png'
import BNBLogo from './assets/bnb.png'
import BTCLogo from './assets/btc.png'
import ETHLogo from './assets/eth.png'
import ETH2Logo from './assets/eth2.png'
import ETHClLogo from './assets/eth_cl.png'
import USDTLogo from './assets/usdt.png'
import TRXLogo from './assets/trx.png'
import USDLogo from './assets/usd.png'
import EUROLogo from './assets/euro.png'
import { CurrencyId } from '@/4_entities/currency'
import { Component } from 'react'

const coinAnimation = keyframes`
  0% {
    background-position-x: 0;
  }

  30% {
    background-position-x: -36px;
  }

  50% {
    background-position-x: -72px;
  }

  80% {
    background-position-x: -36px;
  }

  100% {
    background-position-x: 0;
  }
`

const getCurrencyLogo = (id: string) => {
  switch (id) {
    case 'ADA':
      return ADALogo
    case 'BNB':
      return BNBLogo
    case 'UBC':
      return BTCLogo
    case 'ETH':
      return ETHLogo
    case 'ETH_CL':
      return ETHClLogo
    case 'ETH_2':
      return ETH2Logo
    case 'UST':
    case 'USE':
      return USDTLogo
    case 'TRX':
      return TRXLogo
    case 'USD':
      return USDLogo
    case 'EUR':
      return EUROLogo
  }
}

const wrapLogo = (component: Component) => {
  return `url(${component})`
}

export const CurrencyLogo = styled.div<{ $currency: string }>`
  width: 34px;
  height: 34.75px;
  background-image: ${({ $currency }) => wrapLogo(getCurrencyLogo($currency))};
  background-size: 108.25px 34.75px;
  background-position-x: -36px;
`

const disabledBackground = defaultColorPalette.darkNeutrals400
const selectedBackground = defaultColorPalette.darkPrimary600
const unSelectedBackground = defaultColorPalette.darkNeutrals400
const coinAnimationExpression = css`
  ${coinAnimation} 1s step-end infinite
`

export const Root = styled.div<{
  $isSelected?: boolean
  $isDisabled?: boolean
}>`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  border-radius: 8px;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ $isSelected, $isDisabled }) =>
    $isDisabled
      ? disabledBackground
      : $isSelected
      ? selectedBackground
      : unSelectedBackground};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  transition: all ${defaultTransitionTiming.fastest}s ease;
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? '0px 0px 2px 2px #5F00FF' : 'none'};

  &:hover {
    ${CurrencyLogo} {
      animation: ${({ $isDisabled }) =>
        $isDisabled ? 'none' : coinAnimationExpression};
    }

    box-shadow: ${({ $isDisabled }) =>
      $isDisabled ? 'none' : '0px 0px 1px 1px #5F00FF'};
  }
`

export const Header = styled.div<{
  $isSelected?: boolean
  $isDisabled?: boolean
}>`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 2px 16px;
  margin: -1px -1px 0 -1px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${defaultColorPalette.darkTypeHigh};

  background-color: ${({ $isSelected }) =>
    $isSelected ? selectedBackground : unSelectedBackground};
  text-align: center;
`

export const CurrencyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 8px 16px 12px;
  height: 100%;

  ${MediaMaxS`
    gap: 8px;
    padding: 8px 12px 8px;
  `}
`

export const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  white-space: nowrap;
  overflow: hidden;
`

export const Name = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${defaultColorPalette.darkTypeMedium};
  overflow: hidden;

  ${MediaMaxXs`
    max-width: 49px;
  `}
`
