import React from 'react'
import * as Styled from './styled'

export const GameCardSkeleton = () => {
  return (
    <Styled.Root>
      <Styled.ImageBlock>
        <Styled.ImageLoading />
      </Styled.ImageBlock>
      <Styled.InfoBlock>
        <Styled.TitleLoading />
        <Styled.Badges>
          <Styled.BadgeLoading />
          <Styled.BadgeLoading />
        </Styled.Badges>
      </Styled.InfoBlock>
    </Styled.Root>
  )
}
