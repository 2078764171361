import { getAllGames, getAllGamesFx, getGameById } from './model/events'
import { data } from './model/store'

import { MainService } from '../main-service'

class GamesService extends MainService<typeof data> {
  getAllGames = getAllGames
  getAllGamesFx = getAllGamesFx
  getGameById = getGameById
}

const gamesService = new GamesService(data)

export { gamesService as GamesService }

export * from './types'
