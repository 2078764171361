import { createDomain, split } from 'effector'
import { Languages } from '@/5_shared/localized-message'
import { availableLanguages } from '@/3_features/language-selection/model/data'

const domain = createDomain('language selector domain')

export const selectLanguage = domain.createEvent<string>('select language')

export const dropdownClick = domain.createEvent('click on dropdown')

export const openDropdown = domain.createEvent('open dropdown selection')
export const closeDropdown = domain.createEvent('close dropdown')

export const $selectedLanguage = domain
  .createStore<Languages>(Languages.English)
  .on(selectLanguage, (_, payload) => payload)

export const $readableSelectedLanguage = $selectedLanguage.map(
  (selected) =>
    availableLanguages.find((element) => element.value === selected)?.label ||
    'English'
)

export const $isDropdownOpened = domain
  .createStore<boolean>(false)
  .on(openDropdown, () => true)
  .reset(closeDropdown)
  .reset(selectLanguage)

// effector split has horrible support in ts
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
split({
  source: dropdownClick,
  match: {
    open: $isDropdownOpened.map((val) => !val),
    close: $isDropdownOpened.map((val) => val),
  },
  cases: {
    open: openDropdown,
    close: closeDropdown,
  },
})
