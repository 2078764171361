import { MutableRefObject, useEffect } from 'react'

export function useOutsideClick(
  ref: MutableRefObject<HTMLElement | null>,
  shouldDetectOutsideClick: boolean,
  onOutsideClick: () => void
) {
  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick()
      }
    }

    if (!shouldDetectOutsideClick) {
      document.removeEventListener('click', clickOutside)
      return
    }

    document.addEventListener('click', clickOutside)

    return () => document.removeEventListener('click', clickOutside)
  }, [shouldDetectOutsideClick, onOutsideClick, ref])

  return null
}
