import React, { FC } from 'react'
import * as Styled from './styled'
import { LanguageSelection } from '@/3_features/language-selection'
import { ContactsBlock } from './contacts-block'
import { Media } from '@/5_shared/ui'
import { useStore } from 'effector-react'
import { UserService } from '@/7_services/user'
import { useTranslation } from 'react-i18next'

export const Header: FC = () => {
  const { isLoggedIn } = useStore(UserService.getStore())
  const { t } = useTranslation()
  return (
    <Styled.Root>
      <Media desktop>
        <Styled.MenuBlock>
          <Styled.NavLink to={'/'}>{t('menu.games')}</Styled.NavLink>
          {/* <Styled.NavLink to={'/terms'}>{t('menu.cooperation')}</Styled.NavLink>
          <Styled.NavLink to={'/cooperation'}>
            {t('menu.cooperation')}
          </Styled.NavLink> */}
        </Styled.MenuBlock>

        <ContactsBlock />

        <Styled.LanguageSelectorBlock>
          <LanguageSelection />
        </Styled.LanguageSelectorBlock>
      </Media>

      <Media mobile tablet>
        <Styled.TopRow>
          <ContactsBlock />

          <Styled.LanguageSelectorBlock>
            <LanguageSelection />
          </Styled.LanguageSelectorBlock>
        </Styled.TopRow>

        <Styled.BottomRow>
          <Styled.LogoLink to={'/'}>
            <Styled.Logo />
          </Styled.LogoLink>

          <Styled.BottomBottomRow>
            <Styled.MenuBlock>
              <Styled.NavLink to={'/'}>{t('menu.games')}</Styled.NavLink>
              {/* <Styled.NavLink to={'/terms'}>{t('menu.cooperation')}</Styled.NavLink>
              <Styled.NavLink to={'/cooperation'}>
                {t('menu.cooperation')}
              </Styled.NavLink> */}
            </Styled.MenuBlock>

            {!isLoggedIn && (
              <Styled.LoginButtonWrapper>
                <Styled.LoginButton />
              </Styled.LoginButtonWrapper>
            )}

            {isLoggedIn && <Styled.ProfileBlock />}
          </Styled.BottomBottomRow>
        </Styled.BottomRow>
      </Media>
    </Styled.Root>
  )
}

Header.displayName = 'Header'
