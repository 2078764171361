import { Api } from '@/5_shared/api'

export const login = (data: { login: string; password: string }) =>
  Api.post({ cmd: 'login' }, data)

export const signUp = (data: { phone: string }) =>
  Api.post({ cmd: 'signup' }, data)

export const signUpPhone = (data: {
  user_login: string
  user_password: string
  serial: number
  gh_id: number
}) => Api.post({ cmd: 'user_reg_phone' }, data)

export const signUpPromo = (data: {
  user_login: string
  user_password: string
  promo_code: number
  gh_id: number
}) => Api.post({ cmd: 'user_reg_promo' }, data)

export const passwordReset = (data: { user_login: string }) =>
  Api.post({ cmd: 'password_reset_phone' }, data)

export const passwordResetVerify = (data: {
  user_login: string
  verify_code: string
  new_password: string
}) => Api.post({ cmd: 'password_reset_phone_verify' }, data)

export const codeVerify = (data: { user_login: string; verify_code: string }) =>
  Api.post({ cmd: 'verify_code' }, data)
