import { FC, memo } from 'react'
import * as Styled from './styled'
import { openAuthModal } from '../model'
import { useTranslation } from 'react-i18next'

interface LoginButtonProps {
  className?: string
}

const handleLoginClick = () => {
  openAuthModal()
  // openProfileModal()
}

export const LoginButton: FC<LoginButtonProps> = memo(
  ({ className }: LoginButtonProps) => {
    const { t } = useTranslation()
    return (
      <Styled.LoginButton className={className} onClick={handleLoginClick}>
        <Styled.LoginIcon />
        <Styled.LoginButtonText>{t('login')}</Styled.LoginButtonText>
      </Styled.LoginButton>
    )
  }
)

LoginButton.displayName = 'LoginButton'
