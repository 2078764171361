import React, { FC } from 'react'
import { Media } from '@/5_shared/ui'
import { DesktopVersion } from './desktop-version'
import { MobileVersion } from './mobile-version'
import { useStore } from 'effector-react'
import { GamesService } from '@/7_services/games'
import { $selectedBrand, $selectedGenre } from '@/2_widgets/games-selector'

interface GamesSelectorProps {}

export const GamesSelector: FC<
  GamesSelectorProps
> = ({}: GamesSelectorProps) => {
  const { gettingAllGamesPending, allBrands, allGenres, gamesTotalAmount } =
    useStore(GamesService.getStore())
  const selectedBrand = useStore($selectedBrand)
  const selectedGenre = useStore($selectedGenre)

  return (
    <>
      <Media desktop>
        <DesktopVersion
          isPending={gettingAllGamesPending}
          brands={allBrands}
          genres={allGenres}
          totalAmount={gamesTotalAmount}
          selectedBrand={selectedBrand}
          selectedGenre={selectedGenre}
        />
      </Media>

      <Media mobile tablet>
        <MobileVersion
          isPending={gettingAllGamesPending}
          brands={allBrands}
          genres={allGenres}
          totalAmount={gamesTotalAmount}
          selectedBrand={selectedBrand}
          selectedGenre={selectedGenre}
        />
      </Media>
    </>
  )
}
