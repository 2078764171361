import { FC, memo, useCallback, useRef, useState } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import { useOutsideClick } from '@/5_shared/hooks'
import {
  dropdownClick,
  $isDropdownOpened,
  closeDropdown,
  selectGenre,
  selectBrand,
  $selectedGenre,
  $selectedBrand,
} from '../model'
import { useTranslation } from 'react-i18next'
// import { Genres } from '@/4_entities/genre'

interface MobileVersionProps {
  isPending: boolean
  brands: { [key: string]: number }
  genres: { [key: string]: number }
  totalAmount: number
  selectedBrand: string | null
  selectedGenre: string | null
}

const selectGenreHandler = (genre: string | null) => {
  selectGenre(genre)
}

const selectBrandHandler = (brand: string | null) => {
  selectBrand(brand)
}

const onDropdownClick = () => {
  dropdownClick()
}

export const MobileVersion: FC<MobileVersionProps> = memo(
  ({ brands, genres, totalAmount }: MobileVersionProps) => {
    const { t } = useTranslation()
    const tabs = [
      {
        id: 'Genre',
        title: <Styled.NavTabTitle>{t('tabs.genre')}</Styled.NavTabTitle>,
      },
      {
        id: 'Provider',
        title: <Styled.NavTabTitle>{t('tabs.provider')}</Styled.NavTabTitle>,
      },
    ]
    const [selectedTab, setSelectedTab] = useState<string>(tabs[1].id)
    const thisRef = useRef<HTMLDivElement>(null)
    const selectedGenre = useStore($selectedGenre)
    const selectedBrand = useStore($selectedBrand)
    const isDropdownOpened = useStore($isDropdownOpened)

    useOutsideClick(thisRef, isDropdownOpened, closeDropdown)

    const selectTab = useCallback((tab: string) => {
      setSelectedTab(tab)
    }, [])

    return (
      <Styled.RootMobile>
        <Styled.NavTab
          onSelectTab={selectTab}
          selectedTab={selectedTab}
          tabsToRender={tabs}
        />
        {selectedTab === 'Genre' ? (
          <Styled.Root ref={thisRef}>
            <Styled.SelectedLanguage onClick={onDropdownClick}>
              {selectedGenre ? selectedGenre : t('all')}
              <Styled.SelectedAmount>
                {selectedGenre ? genres[selectedGenre] : totalAmount}
              </Styled.SelectedAmount>
            </Styled.SelectedLanguage>

            {/* todo to shared */}
            <Styled.Dropdown $isShown={isDropdownOpened}>
              <Styled.Option
                onClick={() => {
                  selectGenreHandler(null)
                  onDropdownClick()
                }}
                $isSelected={selectedGenre === null}
              >
                {t('all')}
                <Styled.SelectedAmount>{totalAmount}</Styled.SelectedAmount>
              </Styled.Option>
              {Object.keys(genres).map((genre) => (
                <Styled.Option
                  key={genre}
                  onClick={() => {
                    selectGenreHandler(genre)
                    onDropdownClick()
                  }}
                  $isSelected={selectedGenre === genre}
                >
                  {genre}
                  <Styled.SelectedAmount>{genres[genre]}</Styled.SelectedAmount>
                </Styled.Option>
              ))}
            </Styled.Dropdown>

            <Styled.IconWrapper onClick={onDropdownClick}>
              {isDropdownOpened ? (
                <Styled.ChevronUpIcon />
              ) : (
                <Styled.ChevronDownIcon />
              )}
            </Styled.IconWrapper>
          </Styled.Root>
        ) : (
          <Styled.Root ref={thisRef}>
            <Styled.SelectedLanguage onClick={onDropdownClick}>
              {selectedBrand ? selectedBrand : t('all')}
              <Styled.SelectedAmount>
                {selectedBrand ? brands[selectedBrand] : totalAmount}
              </Styled.SelectedAmount>
            </Styled.SelectedLanguage>

            {/* todo to shared */}
            <Styled.Dropdown $isShown={isDropdownOpened}>
              <Styled.Option
                onClick={() => {
                  selectBrandHandler(null)
                  onDropdownClick()
                }}
                $isSelected={selectedBrand === null}
              >
                {t('all')}
                <Styled.SelectedAmount>{totalAmount}</Styled.SelectedAmount>
              </Styled.Option>
              {Object.keys(brands)
                .sort()
                .map((brand) => (
                  <Styled.Option
                    key={brand}
                    onClick={() => {
                      selectBrandHandler(brand)
                      onDropdownClick()
                    }}
                    $isSelected={selectedBrand === brand}
                  >
                    {brand}
                    <Styled.SelectedAmount>
                      {brands[brand]}
                    </Styled.SelectedAmount>
                  </Styled.Option>
                ))}
            </Styled.Dropdown>

            <Styled.IconWrapper onClick={onDropdownClick}>
              {isDropdownOpened ? (
                <Styled.ChevronUpIcon />
              ) : (
                <Styled.ChevronDownIcon />
              )}
            </Styled.IconWrapper>
          </Styled.Root>
        )}
      </Styled.RootMobile>
    )
  }
)

MobileVersion.displayName = 'MobileVersion'
