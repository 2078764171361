import { FC, memo, useCallback, useRef, useState } from 'react'
import * as Styled from './styled'
import { Config } from '@/5_shared/config'
import { Media } from '@/5_shared/ui'
import { useOutsideClick } from '@/5_shared/hooks'

interface ContactsBlockProps {}

export const ContactsBlock: FC<ContactsBlockProps> = memo(
  ({}: ContactsBlockProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const thisRef = useRef<HTMLDivElement>(null)

    const toggle = useCallback(() => {
      setIsOpen((val) => !val)
    }, [])

    const close = useCallback(() => {
      setIsOpen(false)
    }, [])

    const contacts = [
      {
        contact: Config.tg,
        icon: <Styled.TelegramIcon />,
        href: `https://t.me/GSDeluxeBot`,
      },
      {
        contact: Config.email,
        icon: <Styled.MailIcon />,
        href: `mailto:${Config.email}`,
      },
      {
        contact: `${Config.phone} USA`,
        icon: <Styled.PhoneIcon />,
        href: `tel:${Config.phone}`,
      },
    ]

    useOutsideClick(thisRef, isOpen, close)

    //     <Styled.Option
    //   key={lang.value}
    //   onClick={() => onLanguageSelect(lang.value)}
    //   $isSelected={selectedLanguage === lang.value}
    // >
    //   {lang.label}
    // </Styled.Option>

    return (
      <Styled.Root ref={thisRef}>
        <Media xl>
          {contacts.map((item) => (
            <Styled.ContactsRow key={item.contact}>
              {item.icon}
              <Styled.Contact href={item.href}>{item.contact}</Styled.Contact>
            </Styled.ContactsRow>
          ))}
        </Media>

        <Media l tablet mobile>
          {[contacts[0]].map((item) => (
            <Styled.ContactsRow onClick={toggle} key={item.contact}>
              {item.icon}
              <Styled.Contact>{item.contact}</Styled.Contact>
            </Styled.ContactsRow>
          ))}

          {/* todo to shared */}
          <Styled.Dropdown $isShown={isOpen}>
            {contacts.map((item) => (
              <Styled.ContactsRowDropdown key={item.contact}>
                <Styled.Contact href={item.href}>{item.contact}</Styled.Contact>
              </Styled.ContactsRowDropdown>
            ))}
          </Styled.Dropdown>

          <Styled.IconWrapper onClick={toggle}>
            {isOpen ? <Styled.ChevronUpIcon /> : <Styled.ChevronDownIcon />}
          </Styled.IconWrapper>
        </Media>
      </Styled.Root>
    )
  }
)

ContactsBlock.displayName = 'ContactsBlock'
