import React, { FC, memo, ReactNode } from 'react'
import * as Styled from './styled'
import { ButtonSize, ButtonType, ButtonVariant } from './types'

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  type?: ButtonType
  children?: ReactNode
  fluid?: boolean
  onClick: () => void
  isPending?: boolean
  disabled?: boolean
  nativeType?: string
}

export const Button: FC<ButtonProps> = memo(
  ({
    variant = ButtonVariant.Standard,
    size = ButtonSize.M,
    type = ButtonType.Primary,
    children,
    fluid = false,
    onClick,
    isPending = false,
    disabled = false,
    nativeType,
  }: ButtonProps) => {
    return (
      <Styled.Root
        onClick={onClick}
        $fluid={fluid}
        $variant={variant}
        $size={size}
        $type={type}
        $isPending={isPending}
        $disabled={disabled}
        type={nativeType}
      >
        {children}
      </Styled.Root>
    )
  }
)

Button.displayName = 'Button'
