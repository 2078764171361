import React, { FC } from 'react'
import * as Styled from './styled'

type SkeletonHeadedListProps = {
  title: string
  className?: string
  amount: number
}

export const SkeletonHeadedList: FC<SkeletonHeadedListProps> = ({
  title,
  className,
  amount,
}: SkeletonHeadedListProps) => {
  return (
    <Styled.HeadedListWrapper className={className}>
      <Styled.ListHeader>{title}</Styled.ListHeader>
      <Styled.List>
        {Array.from({ length: amount }).map((_, index) => (
          <Styled.ListItemSkeleton key={index}>
            <Styled.ListAmountSkeleton />
          </Styled.ListItemSkeleton>
        ))}
      </Styled.List>
    </Styled.HeadedListWrapper>
  )
}
