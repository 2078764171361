import { Api } from '@/5_shared/api'

const fetchJackpots = () => {
  return Api.post(
    {
      cmd: 'jackpot_list_all',
    },
    {},
    true
  )
}

export const api = { fetchJackpots }
