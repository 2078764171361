import styled from 'styled-components'
import { defaultColorPalette, MediaMaxL, MediaMaxM } from '@/5_shared/styles'
import RollSrc from './assets/game_roll.png'
import { AutisticText, Icon, IconName } from '@/5_shared/ui'

export const Root = styled.div`
  background: linear-gradient(135deg, #ff0c8c 0%, #ffa035 92.71%);
  border-radius: 120px 0 0 0;
  flex-grow: 1;
  padding-bottom: 32px;
  min-width: 352px;

  ${MediaMaxM`
    border-radius: 0;
    padding-bottom: 18px;
  `}
`

export const Title = styled(AutisticText)`
  margin-bottom: 40px;
  font-size: 42px;
  padding-top: 50px;
  padding-left: 52px;
  background-image: linear-gradient(135deg, #ffff30 0%, #ff8308 100%);
`

export const JackpotsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding-left: 52px;
  padding-right: 52px;

  ${MediaMaxL`
    padding-left: 32px;
    padding-right: 32px;
  `}

  ${MediaMaxM`
    max-width: 100%;
    overflow-x: scroll;
    flex-direction: row;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  `}
`

export const JackpotItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 12px;
  border-radius: 16px;
  background-color: ${defaultColorPalette.darkSurfaceTertiary};
  width: 250px;
  box-sizing: border-box;
`

export const Name = styled.div`
  color: ${defaultColorPalette.white};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const AmountBlock = styled.div`
  width: 229px;
  height: 73px;
  position: relative;
`

export const RollImage = styled.img.attrs({
  src: RollSrc,
})`
  position: absolute;
  z-index: 1;
`

export const Amount = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  top: 17px;
  right: 20px;
`
export const AmountSymbolWrapper = styled.div`
  height: 1.5em;
  overflow: hidden;
  position: relative;
`

export const AmountSymbol = styled.div`
  width: 19px;
  font-size: 24px;
  color: ${defaultColorPalette.black};
  text-align: center;
  user-select: none;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`

export const InfoRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const CurrencyIcon = styled(Icon).attrs({
  name: IconName.CoinBroken,
  color: defaultColorPalette.white,
  width: 20,
  height: 20,
})``

export const Currency = styled.div`
  color: ${defaultColorPalette.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

export const DateIcon = styled(Icon).attrs({
  name: IconName.Calendar01,
  color: defaultColorPalette.white,
  width: 20,
  height: 20,
})``

export const Date = styled.div`
  color: ${defaultColorPalette.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
