import React, { FC, memo } from 'react'
import { useStore } from 'effector-react'
import {
  $isProfileModalOpened,
  $profileModalSelectedTab,
  closeProfileModal,
  ProfileModalTab,
  selectProfileModalTab,
} from '../model'
import { Modal, NavTab, Media, IconName } from '@/5_shared/ui'
import * as Styled from './styled'
import { ProfileTab } from './tabs/profile-tab'
import { CreditsTab } from './tabs/credits-tab'
import { TopUpTab } from './tabs/top-up-tab'
import { WithdrawTab } from './tabs/withdraw-tab'
import { useTranslation } from 'react-i18next'

interface DashboardModalProps {}

const handleCloseModal = () => {
  closeProfileModal()
}

const handleSelectTab = (id: ProfileModalTab) => {
  selectProfileModalTab(id)
}

export const DashboardModal: FC<DashboardModalProps> = memo(
  ({}: DashboardModalProps) => {
    const { t } = useTranslation()
    const ProfileTabs: {
      id: ProfileModalTab
      title: string
      icon: IconName
    }[] = [
      {
        id: ProfileModalTab.Profile,
        title: t('tabs.profile'),
        icon: IconName.EditContained,
      },
      {
        id: ProfileModalTab.Credits,
        title: t('tabs.credits'),
        icon: IconName.Wallet03,
      },
      {
        id: ProfileModalTab.TopUp,
        title: t('tabs.topUp'),
        icon: IconName.Plus01,
      },
      {
        id: ProfileModalTab.Withdraw,
        title: t('tabs.withdraw'),
        icon: IconName.Download01,
      },
    ]
    const isOpen = useStore($isProfileModalOpened)
    const selectedTab = useStore($profileModalSelectedTab)

    return (
      <Modal
        onClose={handleCloseModal}
        isOpen={isOpen}
        defaultView
        renderCloseCross
      >
        <Styled.Root>
          <Media tablet desktop>
            <NavTab
              tabsToRender={ProfileTabs}
              onSelectTab={handleSelectTab}
              selectedTab={selectedTab}
            />
          </Media>

          <Media mobile>
            <NavTab
              tabsToRender={ProfileTabs}
              onSelectTab={handleSelectTab}
              selectedTab={selectedTab}
              positionsToRender={[
                [0, 1],
                [2, 3],
              ]}
            />
          </Media>

          {selectedTab === ProfileModalTab.Profile && <ProfileTab />}
          {selectedTab === ProfileModalTab.Credits && <CreditsTab />}
          {selectedTab === ProfileModalTab.TopUp && <TopUpTab />}
          {selectedTab === ProfileModalTab.Withdraw && <WithdrawTab />}
        </Styled.Root>
      </Modal>
    )
  }
)

DashboardModal.displayName = 'DashboardModal'
