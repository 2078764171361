import { domain } from './domain'
import {
  closeProfileModal,
  copyTopUpWallet,
  firstLastNameChange,
  getPaymentWalletFx,
  getPaymentWallet,
  nicknameChange,
  openProfileModal,
  selectProfileModalTab,
  setMainCurrency,
  setTopUpCurrency,
  setWithdrawAmount,
  setWithdrawCurrency,
  setWithdrawDestinationWallet,
  setMainCurrencyFx,
  passwordChangeEvent,
  confirmPasswordChangeEvent,
  oldPasswordChangeEvent,
  savePassword,
  savePasswordFx,
} from './events'
import { PaymentWallet, ProfileModalTab, UserWalletType } from './types'
import { Currency } from '@/4_entities/currency'
import { combine, forward, guard } from 'effector'
import { PaymentWalletGate } from './gate'

export const $isProfileModalOpened = domain
  .createStore<boolean>(false)
  .on(openProfileModal, () => true)
  .on(selectProfileModalTab, () => true)
  .reset(closeProfileModal)

export const $profileModalSelectedTab = domain
  .createStore<ProfileModalTab>(ProfileModalTab.Profile)
  .on(openProfileModal, (_, payload) => payload || ProfileModalTab.Profile)
  .on(selectProfileModalTab, (_, payload) => payload)
  .reset(closeProfileModal)

// todo to user profile
export const $nicknameInput = domain
  .createStore<string>('')
  .on(nicknameChange, (_, payload) => payload)
  .reset(closeProfileModal)

// todo to user profile
export const $firstLastName = domain
  .createStore<string>('')
  .on(firstLastNameChange, (_, payload) => payload)
  .reset(closeProfileModal)

// todo to profile? or currency?
export const $selectedMainCurrency = domain
  .createStore<number | null>(null)
  .on(setMainCurrency, (_, payload) => payload)

// todo to user profile
export const $userWallet = domain.createStore<UserWalletType>([])

export const $topUpSelectedCurrency = domain
  .createStore<Currency['CurrencyID'] | null>(null)
  .on(setTopUpCurrency, (_, payload) => payload)
  .reset(selectProfileModalTab, closeProfileModal)

export const $withdrawSelectedCurrency = domain
  .createStore<Currency['CurrencyID'] | null>(null)
  .on(setWithdrawCurrency, (_, payload) => payload)
  .reset(selectProfileModalTab, closeProfileModal)

export const $withdrawDesctinationWallet = domain
  .createStore<string>('')
  .on(setWithdrawDestinationWallet, (_, payload) => payload)
  .reset(closeProfileModal, setWithdrawCurrency)

export const $selectedWithdrawAmount = domain
  .createStore<number>(0)
  .on(setWithdrawAmount, (_, payload) => payload)
  .reset(closeProfileModal, setWithdrawCurrency)

export const $isCopied = domain
  .createStore<boolean>(false)
  .on(copyTopUpWallet, () => true)
  .reset(selectProfileModalTab, closeProfileModal, setTopUpCurrency)

forward({
  from: PaymentWalletGate.open,
  to: getPaymentWallet,
})

forward({
  from: setMainCurrency,
  to: setMainCurrencyFx,
})

guard({
  source: getPaymentWallet,
  filter: getPaymentWalletFx.pending.map((pending) => !pending),
  target: getPaymentWalletFx,
})

guard({
  source: getPaymentWallet,
  filter: getPaymentWalletFx.pending.map((pending) => !pending),
  target: getPaymentWalletFx,
})

export const $getPaymentWallet = domain
  .createStore<PaymentWallet | null>(null)
  .on(getPaymentWalletFx.doneData, (_, payload) => payload)

export const $getPaymentWalletNumber = domain
  .createStore<string>('')
  .on(getPaymentWalletFx.doneData, (_, payload) => payload.wallet_number)
  .reset(
    selectProfileModalTab,
    closeProfileModal,
    setTopUpCurrency,
    getPaymentWalletFx.fail
  )

export const $passwordInput = domain
  .createStore<string>('')
  .on(passwordChangeEvent, (_, payload) => payload)
  .reset(closeProfileModal)

export const $oldPasswordInput = domain
  .createStore<string>('')
  .on(oldPasswordChangeEvent, (_, payload) => payload)
  .reset(closeProfileModal)

export const $confirmPasswordInput = domain
  .createStore<string>('')
  .on(confirmPasswordChangeEvent, (_, payload) => payload)
  .reset(closeProfileModal)

const $isPasswordValid = combine(
  $passwordInput,
  $confirmPasswordInput,
  (pass, confirmPass) => pass === confirmPass
)

guard({
  clock: savePassword,
  filter: $isPasswordValid,
  source: {
    old_password: $oldPasswordInput,
    new_password: $passwordInput,
  },
  target: savePasswordFx,
})
