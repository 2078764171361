import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { selectBrand, selectGenre } from '../model'
import { useTranslation } from 'react-i18next'

interface DesktopVersionProps {
  isPending: boolean
  brands: { [key: string]: number }
  genres: { [key: string]: number }
  totalAmount: number
  selectedBrand: string | null
  selectedGenre: string | null
}

const selectGenreHandler = (genre: string | null) => {
  selectGenre(genre)
}

const selectBrandHandler = (brand: string | null) => {
  selectBrand(brand)
}

export const DesktopVersion: FC<DesktopVersionProps> = memo(
  ({
    isPending,
    brands,
    genres,
    totalAmount,
    selectedBrand,
    selectedGenre,
  }: DesktopVersionProps) => {
    const { t } = useTranslation()
    return (
      <Styled.RootDesktop>
        {isPending && (
          <>
            <Styled.GenresSkeletonHeadedList
              amount={3}
              title={t('tabs.genre')}
            />
            <Styled.BrandsSkeletonHeadedList
              amount={4}
              title={t('tabs.provider')}
            />
          </>
        )}

        {!isPending && (
          <>
            <Styled.GenresBlock
              selected={selectedGenre}
              options={genres}
              title={t('tabs.genre')}
              onClick={selectGenreHandler}
              totalAmount={totalAmount}
            />

            <Styled.BrandsBlock
              selected={selectedBrand}
              options={brands}
              title={t('tabs.provider')}
              onClick={selectBrandHandler}
              totalAmount={totalAmount}
            />
          </>
        )}
      </Styled.RootDesktop>
    )
  }
)

DesktopVersion.displayName = 'DesktopVersion'
