import styled, { keyframes } from 'styled-components'

const animationGradient = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 50%;
  }
`

export const Root = styled.div`
  font-family: 'Luckiest Guy';
  background-image: linear-gradient(135deg, #ff0c8c 0%, #ffa035 92.71%);
  background-size: 200% 200%;
  animation: ${animationGradient} 5s ease infinite;
  line-height: 1em;

  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`
