import styled, { css } from 'styled-components'
import { defaultColorPalette, MediaMaxS, MediaMaxXs } from '@/5_shared/styles'
import { Input as InputUi, LabeledInput } from '@/5_shared/ui'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 72px;
`

export const CurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
`

const blockStyles = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -24px;
`

export const AvailableBlock = styled.div`
  ${blockStyles}
`
export const NonAvailableBlock = styled.div`
  ${blockStyles};

  opacity: 0.5;
`

export const SelectCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const SelectCurrencyTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const SelectCurrencyText = styled.div`
  color: ${defaultColorPalette.darkTypeMedium};
`

export const NonAvailableCurrencyWrapper = styled.div`
  flex-basis: calc(33% - 24px);
  height: 84px;

  ${MediaMaxS`
    flex-basis: calc(50% - 20px);
  `}
`

export const CurrencyCardWrapper = styled.div`
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: calc(50% - 20px);
  `}
`

export const CurrencyStepHeadBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  ${MediaMaxS`
    flex-direction: column;
  `}
`

export const CurrencyMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`

export const Labeled = styled(LabeledInput)``

export const Input = styled(InputUi)``

export const CurrencyStepSelectCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-basis: calc(50% - 12px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const CurrencyTabCurrencyCardWrapper = styled.div`
  flex-basis: calc(50% - 12px);
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const AmountBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;

  ${MediaMaxS`
    gap: 16px;
  `}

  ${MediaMaxXs`
    gap: 16px;
  `}
`

export const Amount = styled.div<{ $isSelected: boolean }>`
  flex-basis: calc(50% - 12px);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  border: 1px solid ${defaultColorPalette.darkNeutrals300};
  color: ${defaultColorPalette.white};
  cursor: pointer;
  user-select: none;

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? defaultColorPalette.darkPrimary700
      : defaultColorPalette.darkNeutrals400};

  ${MediaMaxS`
    font-size: 24px;
    line-height: 36px;
    flex-basis: calc(50% - 8px);
  `}

  ${MediaMaxXs`
    font-size: 18px;
    line-height: 32px;
  `}
`
