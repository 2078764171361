import { FC, memo } from 'react'
import { IconName, Modal, NavTab } from '@/5_shared/ui'
import {
  $isAuthModalOpen,
  $selectedAuthTab,
  closeAuthModal,
  selectTab,
} from '../model'
import { useStore } from 'effector-react'
import { SignUpTab } from './tabs/sign-up-tab'
import { LoginTab } from './tabs/login-tab'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'

interface AuthModalProps {}

export const enum AuthTab {
  Login = 'login',
  SignUp = 'sign-up',
}

const handleCloseModal = () => {
  closeAuthModal()
}

const handleSelectTab = (tab: AuthTab) => {
  selectTab(tab)
}

export const AuthModal: FC<AuthModalProps> = memo(({}: AuthModalProps) => {
  const isOpen = useStore($isAuthModalOpen)
  const selectedTab = useStore($selectedAuthTab)
  const { t } = useTranslation()

  const AuthTabs: { id: AuthTab; title: string; icon: IconName }[] = [
    {
      id: AuthTab.SignUp,
      title: t('loginSignupWindow.signUp'),
      icon: IconName.EditContained,
    },
    {
      id: AuthTab.Login,
      title: t('login'),
      icon: IconName.Logout02,
    },
  ]

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={isOpen}
      defaultView
      renderCloseCross
    >
      <Styled.AuthModalRoot>
        <NavTab
          onSelectTab={handleSelectTab}
          selectedTab={selectedTab}
          tabsToRender={AuthTabs}
        />
        {selectedTab === AuthTab.SignUp && <SignUpTab />}
        {selectedTab === AuthTab.Login && <LoginTab />}
      </Styled.AuthModalRoot>
    </Modal>
  )
})

AuthModal.displayName = 'AuthModal'
