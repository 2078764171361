import styled from 'styled-components'
import { defaultColorPalette, defaultTransitionTiming } from '@/5_shared/styles'
import { Icon } from '../icon'

export const Root = styled.div`
  position: relative;
`

export const NavTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
  border-radius: 96px;
  background-color: ${defaultColorPalette.darkNeutrals800};

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const NavTabText = styled.div`
  color: ${defaultColorPalette.white};
`

export const NavTab = styled.div<{ $isSelected: boolean }>`
  z-index: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  border-radius: 96px;
  user-select: none;
  white-space: nowrap;
`

export const NavTabIcon = styled(Icon).attrs({
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})``

export const NavTabSelectedUnderlayer = styled.div<{
  $offsetLeft?: number
  $offsetTop?: number
  $offsetWidth?: number
  $offsetHeight?: number
}>`
  transition: all ${defaultTransitionTiming.fast}s
    cubic-bezier(0.47, 1.64, 0.41, 0.8);
  z-index: 1;
  position: absolute;
  border-radius: 100px;

  left: ${({ $offsetLeft }) => $offsetLeft}px;
  top: ${({ $offsetTop }) => $offsetTop}px;
  width: ${({ $offsetWidth }) => $offsetWidth}px;
  height: ${({ $offsetHeight }) => $offsetHeight}px;
  background-color: ${defaultColorPalette.darkPrimary600};
`
