import { domain } from './domain'
import { getAllGames, getAllGamesFx, getGameById, selectGame } from './events'
import { combine, guard } from 'effector'
import { Game } from '../types'

const $fetchedGamesList = domain
  .createStore<Game[]>([])
  .on(getAllGamesFx.doneData, (_, payload) => payload.gamelist)

const $fetchedActualGame = domain
  .createStore<string | null>(null)
  .on(getGameById.doneData, (_, payload) => payload)

const $fetchedBestGames = domain
  .createStore([])
  .on(getAllGamesFx.doneData, (_, payload) => payload.best_games)

const $brandsFromGamesList = $fetchedGamesList.map((data) => {
  const brands: { [key: string]: number } = {}

  data.forEach((item) => {
    if (brands[item.Platform]) {
      brands[item.Platform] += 1
    } else {
      brands[item.Platform] = 1
    }
  })

  return brands
})

const $gamesTotalAmount = $fetchedGamesList.map((data) => data.length)

const $genresFromGamesList = $fetchedGamesList.map((data) => {
  const genres: { [key: string]: number } = {}

  data.forEach((item) => {
    if (genres[item.Genre]) {
      genres[item.Genre] += 1
    } else {
      genres[item.Genre] = 1
    }
  })

  return genres
})

const $fetchedBaseUrl = domain
  .createStore('')
  .on(getAllGamesFx.doneData, (_, payload) => payload.baseURL)

export const $selectedGame = domain
  .createStore(null)
  .on(selectGame, (_, payload) => payload)

const $allGamesList = domain.createStore(null)

guard({
  source: getAllGames,
  filter: getAllGamesFx.pending.map((pending) => !pending),
  target: getAllGamesFx,
})

const $gettingAllGamesPending = getAllGamesFx.pending

export const data = combine({
  bestGames: $fetchedBestGames,
  gamesList: $fetchedGamesList,
  allBrands: $brandsFromGamesList,
  allGenres: $genresFromGamesList,
  allGamesList: $allGamesList,
  gamesTotalAmount: $gamesTotalAmount,
  gettingAllGamesPending: $gettingAllGamesPending,
  baseIconUrl: $fetchedBaseUrl,
  actualGame: $fetchedActualGame,
  selectedGame: $selectedGame,
})
