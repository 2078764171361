import React, { ChangeEvent, FC, memo } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import { $searchValue, onInputSearch } from '../model'
import { useTranslation } from 'react-i18next'

interface SearchInputProps {
  className?: string
}

const onInputSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
  onInputSearch(event.target.value)
}

export const SearchInput: FC<SearchInputProps> = memo(
  ({ className }: SearchInputProps) => {
    const value = useStore($searchValue)
    const { t } = useTranslation()

    return (
      <Styled.SearchWrapper className={className}>
        <Styled.SearchIcon />
        <Styled.Search
          value={value}
          onChange={onInputSearchHandler}
          placeholder={t('search')}
          autocomplete="off"
          name="search"
        />
      </Styled.SearchWrapper>
    )
  }
)

SearchInput.displayName = 'SearchInput'
