import { createGlobalStyle } from 'styled-components'
import { defaultColorPalette } from '@/5_shared/styles'

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, Public Sans, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    
    background-color: ${defaultColorPalette.darkSurfaceSecondary};
    min-width: 375px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
`

export { GlobalStyles }
