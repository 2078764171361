const decodeJWT = (token: string) => {
  const parts = token.split('.')
  const decodedParts: string[] = []

  parts.forEach((part: string) => {
    const decodedPart = atob(part.replace(/-/g, '+').replace(/_/g, '/'))
    decodedParts.push(decodedPart)
  })

  return decodedParts
}

export const getUserId = (jwt: string) => {
  const parts = decodeJWT(jwt)

  return JSON.parse(parts[1]).user_id
}
