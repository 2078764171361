import React, { FC, memo } from 'react'
import PhoneInputLib from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './styles.module.css'

type PhoneInputProps = {
  value: string
  onChange: (phone: string) => void
}

export const PhoneInput: FC<PhoneInputProps> = memo(
  ({ onChange, value }: PhoneInputProps) => {
    return (
      <PhoneInputLib
        buttonClass={styles.buttonClass}
        inputClass={styles.inputClass}
        dropdownClass={styles.dropdownClass}
        onChange={onChange}
        value={value}
        country="us"
      />
    )
  }
)

PhoneInput.displayName = 'PhoneInput'
