import React, { FC, memo } from 'react'
import { $withdrawSelectedCurrency } from '../../../model'
import { useStore } from 'effector-react'
import { CurrencyStep } from './currency-step'
import { MainStep } from './main-step'

interface WithdrawTabProps {}

export const WithdrawTab: FC<WithdrawTabProps> = memo(
  ({}: WithdrawTabProps) => {
    const withdrawSelectedCurrency = useStore($withdrawSelectedCurrency)

    return withdrawSelectedCurrency ? <CurrencyStep /> : <MainStep />
  }
)

WithdrawTab.displayName = 'WithdrawTab'
