import { css } from 'styled-components'
import type { CSSObject } from 'styled-components'

const BREAKPOINTS = {
  xl: 1440,
  l: 1280,
  m: 1024,
  s: 768,
  xs: 375,
  xxs: 320,
}

const max = (pixels: number) => `(max-width: ${pixels - 0.05}px)`

const createMediaQuery =
  (condition: string) =>
  (...args: [CSSObject | TemplateStringsArray]) => {
    return css`
      @media screen and ${condition} {
        ${css(...args)}
      }
    `
  }

/**
 * below 1440px
 * @return `@media screen and (max-width: 1440px) { ...args }`
 */
export const MediaMaxXl = createMediaQuery(max(BREAKPOINTS.xl))

/**
 * below 1280px
 * @return `@media screen and (max-width: 1280px) { ...args }`
 */
export const MediaMaxL = createMediaQuery(max(BREAKPOINTS.l))

/**
 * below 1024px
 * @return `@media screen and (max-width: 1024px) { ...args }`
 */
export const MediaMaxM = createMediaQuery(max(BREAKPOINTS.m))

/**
 * below 768px
 * @return `@media screen and (max-width: 768px) { ...args }`
 */
export const MediaMaxS = createMediaQuery(max(BREAKPOINTS.s))

/**
 * below 375px
 * @return `@media screen and (max-width: 375px) { ...args }`
 */
export const MediaMaxXs = createMediaQuery(max(BREAKPOINTS.xs + 1))
