import { domain } from './domain'
import { AuthTab } from '../ui/auth-modal'

export const openAuthModal = domain.createEvent('open auth modal')

export const closeAuthModal = domain.createEvent('close auth modal')

export const closeAuthModalEvent = domain.createEvent('close auth modal')

// todo: to separate component with model, events, ui, fabric creation
export const selectTab = domain.createEvent<AuthTab>('select tab in auth modal')

export const login = domain.createEvent('try to login')

export const nicknameChangeEvent = domain.createEvent<string>(
  'on input nickname value'
)

export const passwordChangeEvent = domain.createEvent<string>(
  'on input password value'
)

export const confirmPasswordChangeEvent = domain.createEvent<string>(
  'on input confirm password value'
)

export const phoneChangeEvent = domain.createEvent<string>(
  'on input phone value'
)

export const signUp = domain.createEvent('try to sign up')

export const checkTermCheckbox = domain.createEvent<boolean>(
  'check checkbox with terms and conditions'
)

export const serialChangeEvent = domain.createEvent<string>('change serial')

export const codeChangeEvent = domain.createEvent<string>('change code')
