import { Currency } from '@/4_entities/currency'
import { Api } from '@/5_shared/api'

type PaymentWallet = {
  user_id: string
  payment_type: number
}

type Withdraw = PaymentWallet & {
  wallet_number: string
  sum: number
}

const fetchPaymentWallet = ({ user_id, payment_type }: PaymentWallet) => {
  return Api.post(
    {
      cmd: 'payment_request_input',
    },
    {
      user_id,
      payment_type,
    }
  )
}

const fetchWithdraw = ({
  user_id,
  payment_type,
  wallet_number,
  sum,
}: Withdraw) => {
  return Api.post(
    {
      cmd: 'payment_request_output',
    },
    {
      wallet_number,
      user_id,
      payment_type,
      sum,
    }
  )
}

const setMainCurrency = (id: number) => {
  return Api.post(
    {
      cmd: 'currency_change',
    },
    {
      currency_id: id,
    }
  )
}

const savePassword = (data: object | undefined) => {
  return Api.post(
    {
      cmd: 'password_change',
    },
    data
  )
}

export const api = {
  fetchPaymentWallet,
  fetchWithdraw,
  setMainCurrency,
  savePassword,
}
