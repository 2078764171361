import { css, keyframes } from 'styled-components'
import { defaultColorPalette } from '../colors'

const shimmer = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
`

export const shimmerBackground = (
  color1 = defaultColorPalette.darkNeutrals200,
  color2 = defaultColorPalette.darkNeutrals400,
  color3 = defaultColorPalette.darkNeutrals600
) => css`
  background: linear-gradient(
    90deg,
    ${color1} 25%,
    ${color2} 50%,
    ${color3} 75%
  );
  background-size: 400% 100%;
  animation: ${shimmer} 2s infinite linear;
`
