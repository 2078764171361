import { FC } from 'react'
import { FilteredGamesList } from '@/2_widgets/filtered-games-list'
import { MainLayout } from '@/1_pages/shared-layouts'
import * as Styled from './styled'

type MainPageProps = object

export const MainPage: FC<MainPageProps> = ({}: MainPageProps) => {
  return (
    <MainLayout>
      <Styled.MainContentWrapper>
        {/* <Styled.BonusBanner /> */}
        <FilteredGamesList />
      </Styled.MainContentWrapper>
    </MainLayout>
  )
}

MainPage.displayName = 'MainPage'
