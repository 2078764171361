import React, { FC, useEffect, useState } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import { QRCodeCanvas } from 'qrcode.react'
import {
  $getPaymentWallet,
  $isCopied,
  $topUpSelectedCurrency,
  copyTopUpWallet,
  setTopUpCurrency,
  getPaymentWalletFx,
  $getPaymentWalletNumber,
} from '../../../model'
import { Button, ButtonType, ButtonVariant } from '@/5_shared/ui'
import { CurrencyCard } from '@/4_entities/currency/ui'
import { UserService } from '@/7_services/user'
import { convertSecondsToTime } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { $fetchedPaymentSystems } from '@/2_widgets/profile-block'
import { Currency } from '@/4_entities/currency'

const handleResetCurrency = () => {
  setTopUpCurrency(null)
}

export const CurrencyStep: FC = () => {
  const topUpSelectedCurrency = useStore($topUpSelectedCurrency)
  const paymentWallet = useStore($getPaymentWallet)
  const walletNumber = useStore($getPaymentWalletNumber)
  const isCopied = useStore($isCopied)
  const paymentSystems = useStore($fetchedPaymentSystems)
  const { user } = useStore(UserService.getStore())
  const [time, setTime] = useState('')

  const handleCopy = () => {
    navigator.clipboard.writeText(walletNumber)
    copyTopUpWallet()
  }
  const { t } = useTranslation()

  useEffect(() => {
    const system = paymentSystems.find(
      (sys) => sys.system_currency === topUpSelectedCurrency
    )
    getPaymentWalletFx({
      user_id: user?.user_id,
      payment_type: system?.id,
    })
  }, [])

  useEffect(() => {
    if (paymentWallet) {
      const timeObj = convertSecondsToTime(paymentWallet.timeout)
      setTime(`${timeObj.hours}:${timeObj.minutes}:${timeObj.seconds}`)
    }
  }, [paymentWallet])

  if (topUpSelectedCurrency === null) {
    return null
  }

  return (
    <>
      <Styled.CurrencyMainWrap>
        <Styled.CurrencyStepHeadBlock>
          <Styled.CurrencyStepSelectCurrencyWrapper>
            <Styled.SelectCurrencyTitle>
              {t('topUpTab.currency')}
            </Styled.SelectCurrencyTitle>
            <Styled.SelectCurrencyText>
              {t('topUpTab.currencyText')}
            </Styled.SelectCurrencyText>
          </Styled.CurrencyStepSelectCurrencyWrapper>

          <Styled.CurrencyTabCurrencyCardWrapper>
            <CurrencyCard
              currency={user?.currency_list.find(
                (cur: Currency) => cur.CurrencyID === topUpSelectedCurrency
              )}
            />
          </Styled.CurrencyTabCurrencyCardWrapper>
        </Styled.CurrencyStepHeadBlock>

        <Styled.Labeled
          onRightButtonClick={handleCopy}
          rightContent={
            <Styled.Copy>{isCopied ? 'copied!' : 'copy'}</Styled.Copy>
          }
          label={t('topUpTab.walletNumber')}
        >
          <Styled.Input value={walletNumber} disabled />
        </Styled.Labeled>
        <Styled.QrcodeWrapper>
          <QRCodeCanvas
            value={walletNumber}
            width={2}
            height={2}
            size={256}
          ></QRCodeCanvas>
        </Styled.QrcodeWrapper>

        <Styled.TextBlock>
          {t('topUpTab.timerLabel')}: {time}
        </Styled.TextBlock>
      </Styled.CurrencyMainWrap>

      <Button
        type={ButtonType.Neutral}
        variant={ButtonVariant.Inverted}
        fluid
        onClick={handleResetCurrency}
      >
        {t('buttons.cancel')}
      </Button>
    </>
  )
}

CurrencyStep.displayName = 'CurrencyStep'
