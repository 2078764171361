import styled from 'styled-components'
import { defaultColorPalette, defaultTransitionTiming } from '@/5_shared/styles'

export const Root = styled.input.attrs({
  type: 'email',
  placeholder: 'Please, enter your phone',
})`
  padding: 10px 12px;
  border-radius: 4px;
  border: 1.5px ${defaultColorPalette.darkNeutrals400} solid;
  outline: none;
  background-color: transparent;
  color: ${defaultColorPalette.white};
  transition: all ${defaultTransitionTiming.fastest}s ease-in-out;
  opacity: ${({ disabled }) => (disabled ? '0.7' : 1)};
`
