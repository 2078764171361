import { domain } from './domain'
import { Game } from '@/7_services/games'

export const onInputSearch = domain.createEvent<string>(
  'filtered games list, on input search'
)

export const setFilteredData = domain.createEvent<Game[]>(
  'filtered games list, set filtered data'
)
