import React, { FC, memo, ReactNode } from 'react'
import * as Styled from './styled'

interface AutisticTextProps {
  children: ReactNode
  className?: string
}

export const AutisticText: FC<AutisticTextProps> = memo(
  ({ children, className }: AutisticTextProps) => {
    return <Styled.Root className={className}>{children}</Styled.Root>
  }
)

AutisticText.displayName = 'AutisticText'
