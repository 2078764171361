export const enum Languages {
  English = 'en',
  Russian = 'ru',
  Albanian = 'sq',
  Greek = 'gr',
  // Spanish = 'Spanish',
  // Polish = 'pl',
  // Chinese = 'Chinese',
  // Japanese = 'Japanese',
  // German = 'German',
  // French = 'French',
  // Portugal = 'Portugal',
}
