import { FC, memo } from 'react'
import * as Styled from './styled'
import { Config } from '@/5_shared/config'
import { Media } from '@/5_shared/ui'
import { useTranslation } from 'react-i18next'

interface FooterProps {}

export const Footer: FC<FooterProps> = memo(({}: FooterProps) => {
  const { t } = useTranslation()
  return (
    <Styled.Root>
      <Media desktop tablet>
        <Styled.Copyright>© 2024, {t('rights')}</Styled.Copyright>
      </Media>

      <Styled.Contacts>
        <Styled.ContactWrapper>
          <Styled.TelegramIcon />
          <Styled.Contact>{Config.tg}</Styled.Contact>
        </Styled.ContactWrapper>
        <Styled.ContactWrapper>
          <Styled.IconEmail />
          <Styled.Contact>{Config.email}</Styled.Contact>
        </Styled.ContactWrapper>
        <Styled.ContactWrapper>
          <Styled.IconPhone />
          <Styled.Contact>{Config.phone}</Styled.Contact>
        </Styled.ContactWrapper>
      </Styled.Contacts>

      <Styled.LogoWrapper>
        <Styled.Logo />

        <Media mobile>
          <Styled.Copyright>© 2024, {t('rights')}</Styled.Copyright>
        </Media>
      </Styled.LogoWrapper>
    </Styled.Root>
  )
})

Footer.displayName = 'Footer'
