import * as Styled from './styled'

interface IDigit {
  number: number
  duration?: number
  cycle?: number
}

export const Digit = ({ number, duration = 3000 }: IDigit) => {
  return (
    <Styled.AmountSymbolWrapper>
      <Styled.AmountSymbol
        style={{
          transform: `translateY(-${number}0%)`,
          transition: `${duration}ms`,
        }}
      >
        <div>0</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
      </Styled.AmountSymbol>
    </Styled.AmountSymbolWrapper>
  )
}
