import i18next from 'i18next'
import ICU from 'i18next-icu'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
i18next
  .use(ICU)
  .use(initReactI18next)
  .use(resourcesToBackend((language: unknown) => import(`./${language}.json`)))
  .use(LngDetector)
  .init({
    interpolation: {
      escapeValue: true,
    },
    fallbackLng: 'en',
    debug: true,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
    supportedLngs: ['ru', 'en', 'sq', 'gr'],
  })

export default i18next
