import { api } from '../api'
import { domain } from './domain'

export const dropdownClick = domain.createEvent('click on dropdown')

export const openDropdown = domain.createEvent('open dropdown selection')
export const closeDropdown = domain.createEvent('close dropdown')

export const getBalances = domain.createEvent('try to get balances')
export const getBalancesFx = domain.createEffect('get balances', {
  handler: api.fetchBalances,
})
