import styled, { css } from 'styled-components'
import { ButtonType, ButtonSize, ButtonVariant } from './types'
import { defaultColorPalette, defaultTransitionTiming } from '@/5_shared/styles'

function selectColor(type: ButtonType, variant: ButtonVariant) {
  const transparentHoverBackground = 'rgba(256,256,256,0.05)'
  const transparentActiveBackground = 'rgba(256,256,256,0.1)'

  switch (type) {
    case ButtonType.Primary:
      return {
        background:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkPrimary600
            : 'transparent',
        border: defaultColorPalette.darkPrimary600,

        hoverBackground:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkPrimary700
            : transparentHoverBackground,
        hoverBorder: defaultColorPalette.darkPrimary700,

        activeBackground:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkPrimary800
            : transparentActiveBackground,
        activeBorder: defaultColorPalette.darkPrimary800,
      }

    case ButtonType.Neutral:
      return {
        background:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkNeutrals400
            : 'transparent',
        border: defaultColorPalette.darkNeutrals400,

        hoverBackground:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkNeutrals500
            : transparentHoverBackground,
        hoverBorder: defaultColorPalette.darkNeutrals500,

        activeBackground:
          variant === ButtonVariant.Standard
            ? defaultColorPalette.darkNeutrals600
            : transparentActiveBackground,
        activeBorder: defaultColorPalette.darkNeutrals600,
      }
  }
}

function preparePaddings(size: ButtonSize) {
  switch (size) {
    case ButtonSize.Xs:
      return '6px 16px'
    case ButtonSize.M:
      return '14px 24px'
  }
}

function prepareFontSize(size: ButtonSize) {
  switch (size) {
    case ButtonSize.Xs:
      return {
        size: 14,
        lineHeight: 20,
      }
    case ButtonSize.M:
      return {
        size: 16,
        lineHeight: 24,
      }
    default:
      return {}
  }
}

const prepareCss = (
  type: ButtonType,
  variant: ButtonVariant,
  size: ButtonSize
) => {
  const colors = selectColor(type, variant)
  const paddings = preparePaddings(size)
  const fontSizes = prepareFontSize(size)

  return css`
    background-color: ${colors.background};
    border-width: 1.5px;
    border-style: solid;
    border-color: ${colors.border};
    padding: ${paddings};
    font-size: ${fontSizes.size}px;
    line-height: ${fontSizes.lineHeight}px;

    &:hover {
      background-color: ${colors.hoverBackground};
      border-color: ${colors.hoverBorder};
    }

    &:active {
      background-color: ${colors.activeBackground};
      border-color: ${colors.activeBorder};
    }
  `
}

export const Root = styled.button<{
  $type: ButtonType
  $size: ButtonSize
  $variant: ButtonVariant
  $fluid: boolean
  $isPending: boolean
  $disabled: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: ${({ $isPending, $disabled }) =>
    $isPending ? 'wait' : $disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  box-sizing: border-box;
  font-weight: 500;
  color: ${defaultColorPalette.white};
  width: ${({ $fluid }) => ($fluid ? '100%' : 'auto')};
  transition: all ${defaultTransitionTiming.fastest}s ease;
  white-space: nowrap;
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};

  ${({ $type, $variant, $size }) => prepareCss($type, $variant, $size)}
`
