import styled from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxS,
} from '@/5_shared/styles'
import { Icon, IconName } from '../icon'

export const ModalPaper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
  border-radius: 16px;
  background-color: ${defaultColorPalette.darkSurfacePrimary};

  ${MediaMaxS`
    padding: 24px;
    margin-left: 12px;
    margin-right: 12px;
  `}
`

export const CloseCross = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${defaultColorPalette.darkPrimary600};
  cursor: pointer;
  transition: all ${defaultTransitionTiming.faster}s ease;

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary700};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const CloseIcon = styled(Icon).attrs({
  name: IconName.X03,
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})``
