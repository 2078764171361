import { domain } from './domain'
import { getJackpots, getJackpotsFx } from './events'
import { JackpotsGate } from './gate'
import { guard, forward, combine } from 'effector'
import { JackPot } from '../types'

export const $fetchedJackPotList = domain
  .createStore<JackPot[]>([])
  .on(getJackpotsFx.doneData, (_, payload) => payload.jackpot_list)

forward({
  from: JackpotsGate.open,
  to: getJackpots,
})

guard({
  source: getJackpots,
  filter: getJackpotsFx.pending.map((pending) => !pending),
  target: getJackpotsFx,
})

export const data = combine({
  jackpots: $fetchedJackPotList,
})
