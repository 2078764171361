import { FC, memo } from 'react'
import * as Styled from './styled'
import { JackPot } from '../types'
import { Digits } from './digits'

interface JackpotItemProps {
  jackpot: JackPot
}

export const JackpotItem: FC<JackpotItemProps> = memo(
  ({ jackpot }: JackpotItemProps) => {
    return (
      <Styled.JackpotItem>
        <Styled.InfoRowWrapper>
          <Styled.CurrencyIcon />
          <Styled.Currency>{jackpot.CurrencyShortName}</Styled.Currency>
        </Styled.InfoRowWrapper>
        <Styled.AmountBlock>
          <Styled.RollImage />
          <Digits value={+jackpot.CurrentAmount.toFixed(2)} duration={700} />
        </Styled.AmountBlock>
      </Styled.JackpotItem>
    )
  }
)

JackpotItem.displayName = 'JackpotItem'
