import styled from 'styled-components'
import { Button, ButtonSize, Icon, IconName } from '@/5_shared/ui'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
} from '@/5_shared/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  gap: 12px;
`

export const Root = styled.div<{ $isShown?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${({ $isShown }) =>
    $isShown ? defaultColorPalette.darkPrimary800 : 'inherit'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 4px;
  gap: 12px;
`

export const Btn = styled(Button).attrs({ size: ButtonSize.Xs })``

export const ProfileAvatarIcon = styled(Icon).attrs({
  name: IconName.UserProfile03,
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})``

export const ImageWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${defaultColorPalette.darkPrimary800};
  cursor: pointer;
`
export const ProfileTitle = styled.div`
  color: ${defaultColorPalette.darkPrimary600};
  font-weight: 500;
  flex-grow: 1;
  padding-left: 8px;
`

export const ProfileInfoBalance = styled.div`
  color: ${defaultColorPalette.white};
  font-weight: 500;
  flex-grow: 1;
  padding-top: 10px;
  align-items: center;
  display: flex;
`

export const ProfileInfo = styled.div`
  cursor: pointer;
  font-weight: 500;
  flex-grow: 1;
`

export const Dropdown = styled.div<{ $isShown: boolean }>`
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
  opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
  position: absolute;
  z-index: ${BASE_Z_INDEX.DROPDOWN};
  top: 66px;
  left: 0;
  right: 5px;
  background-color: ${defaultColorPalette.darkPrimary700};
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 24px 12px 24px 12px;
  transition: opacity ${defaultTransitionTiming.faster}s ease;
  width: 100%;
  box-sizing: border-box;
`
export const Option = styled.div<{ $isSelected: boolean }>`
  color: ${defaultColorPalette.white};
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  transition: background-color ${defaultTransitionTiming.fastest}s ease;
  user-select: none;
  border-radius: 6px;
  display: flex;
  align-items: center;

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? defaultColorPalette.darkPrimary800
      : defaultColorPalette.darkPrimary700};

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary800};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`
export const DropdownIcon = styled(Icon).attrs({
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})`
  margin-right: 8px;
`
