import { domain } from './domain'
import { onInputSearch, setFilteredData } from './events'
import { debounce } from 'patronum'
import { FilteredGamesListGate } from './gate'
import { forward, combine } from 'effector'
import { Game, GamesService } from '@/7_services/games'
import { $selectedBrand, $selectedGenre } from '../../games-selector'

export const $searchValue = domain
  .createStore('')
  .on(onInputSearch, (_, payload) => payload)

forward({
  from: FilteredGamesListGate.open,
  to: GamesService.getAllGames,
})

export const $filteredGames = domain
  .createStore<Game[]>([])
  .on(setFilteredData, (_, payload) => payload)

export const $filteredAndSelectedGames = combine(
  $filteredGames,
  $selectedGenre,
  $selectedBrand,
  (games, genre, brand) => {
    return games.filter(
      (game) =>
        (genre !== null ? game.Genre === genre : true) &&
        (brand !== null ? game.Platform === brand : true)
    )
  }
)

export const $loading = GamesService.getStore().map(
  ({ gettingAllGamesPending }) => gettingAllGamesPending
)

debounce({
  source: combine(
    $searchValue,
    GamesService.getStore().map(({ gamesList }) => gamesList),
    (search, games) => ({
      search,
      games,
    })
  ),
  timeout: 100,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  target: setFilteredData.prepend(({ search, games }) => {
    return games.filter((el: Game) => {
      const targetSearch = search.toLowerCase()
      return (
        el.Name.toLowerCase().includes(targetSearch) ||
        el.Genre.toLowerCase().includes(targetSearch) ||
        el.Platform.toLowerCase().includes(targetSearch)
      )
    })
  }),
})
