import {
  login,
  loginFx,
  logout,
  signUp,
  signUpPhone,
  signUpPromo,
  passwordReset,
  passwordResetVerify,
  codeVerify,
  registrationRequest,
  codeVerifyFx,
} from './model/events'
import { data } from './model/store'

import { MainService } from '../main-service'

class UserService extends MainService<typeof data> {
  signUp = signUp
  signUpPhone = signUpPhone
  signUpPromo = signUpPromo
  login = login
  loginFx = loginFx
  logout = logout
  passwordReset = passwordReset
  passwordResetVerify = passwordResetVerify
  codeVerify = codeVerify
  registrationRequest = registrationRequest
  codeVerifyFx = codeVerifyFx
}

const userService = new UserService(data)

export { userService as UserService }
