export const defaultColorPalette = {
  white: '#ffffff',
  black: '#000000',

  darkPrimary1100: '#261051',
  darkPrimary1000: '#3E1E71',
  darkPrimary900: '#553098',
  darkPrimary800: '#6D40C8',
  darkPrimary700: '#8C57E6',
  darkPrimary600: '#A471F6',
  darkPrimary500: '#BC8CFE',
  darkPrimary400: '#D2A9FF',
  darkPrimary300: '#E2C4FE',
  darkPrimary200: '#E5D6FF',
  darkPrimary100: '#E8E0FF',

  darkNeutrals1100: '#0E1216',
  darkNeutrals1000: '#141A1F',
  darkNeutrals900: '#1A2028',
  darkNeutrals800: '#202831',
  darkNeutrals700: '#26303B',
  darkNeutrals600: '#2C3844',
  darkNeutrals500: '#32404D',
  darkNeutrals400: '#384857',
  darkNeutrals300: '#3E5060',
  darkNeutrals200: '#445869',
  darkNeutrals100: '#4A6073',

  darkSurfacePrimary: '#0E1216',
  darkSurfaceSecondary: '#1A2028',
  darkSurfaceTertiary: '#26303B',

  darkTypeHigh: '#F8FAFC',
  darkTypeMedium: '#94A3B8',

  lightPrimary300: '#C7B5FF',

  lightAccentPink600: '#FF0C8C',

  lightAccentOrange500: '#FFA035',

  lightNeutral1100: '#070A13',
  lightNeutral600: '#64748B',

  lightTypeMedium: '#64748B',
}

const allowedHexColorCodes = /^#?([0-9a-fA-F]{3}){1,2}$/
const getHexCodePattern = (groupLength: number) =>
  new RegExp(`([A-Fa-f0-9]){${groupLength}}`, 'g')

export function hexToRgbA(hex: string, alpha?: number): string {
  if (!allowedHexColorCodes.test(hex)) {
    throw new Error(`Provided HEX color code "${hex}" is incorrect`)
  }

  const codeGroupLength = Math.floor(hex.length / 3)
  const hexCodePattern = getHexCodePattern(codeGroupLength)
  const [r, g, b] = [...hex.matchAll(hexCodePattern)].map(([code]) =>
    parseInt(code.padEnd(2, code), 16)
  )

  if (typeof alpha === 'number') {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  return `rgb(${r}, ${g}, ${b})`
}
