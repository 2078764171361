import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { SpecialOfferTopup } from './special-offer-topup'
import { MainLayout } from '@/1_pages/shared-layouts'

interface TermsPageProps {}

export const TermsPage: FC<TermsPageProps> = memo(({}: TermsPageProps) => {
  return (
    <MainLayout>
      <Styled.MainContentWrapper>
        <Styled.MainHeader>Terms</Styled.MainHeader>

        <Styled.TermsBlock>
          <Styled.SubHeader1>
            So, if you are on our page, then you:
          </Styled.SubHeader1>

          <Styled.SubHeader2>
            Want to have a good time, or, in other words - to play one of games
            of online casino "GSDeluxe"!
          </Styled.SubHeader2>

          <Styled.Text>
            In this case, you need to download the application of the game
            client of our casino (“Downloads” tab). After application download
            and launch your account is automatically topped up with 50 euros (5
            000 credits). In addition, we give you an opportunity to purchase
            additional free credits – 20 000 of them arrive into your account
            after 2 euros payment. Now a special offer is valid!
          </Styled.Text>

          {/* <Styled.SpecialOfferTopupWrapper>
            <SpecialOfferTopup />
            <SpecialOfferTopup />
          </Styled.SpecialOfferTopupWrapper> */}

          <Styled.SubHeader1>
            Or you want to try your luck and hit the jackpot in our!
          </Styled.SubHeader1>

          <Styled.Text>
            To do so, you need to be aware of the following features of our
            system.
          </Styled.Text>

          <Styled.TextEnumerable>
            <Styled.TextCount>1</Styled.TextCount>
            <Styled.Text>
              Payment of the credits and payment of a win is performed directly
              via the administrator of a game system – for this purpose, it is
              necessary to contact us through the technical support system
              (“contacts” tab or a chat in the bottom of the screen).
            </Styled.Text>
          </Styled.TextEnumerable>

          <Styled.TextEnumerable>
            <Styled.TextCount>2</Styled.TextCount>
            <Styled.Text>
              All payments and returns to player are performed via Bitcoin
              payment system. Deposit of funds on your Bitcoin-wallet or funds
              transfer to the administrator can take 30 minutes to 1-2 hours
              that is due to the features of this payment system.In order to
              transfer of your own funds to bitcoin system, use the following
              resources
            </Styled.Text>
          </Styled.TextEnumerable>

          <Styled.SubHeader2>
            Perhaps, you want to create your own game hall and to provide full
            access to the game store of "GSDeluxe" to your clients!
          </Styled.SubHeader2>

          <Styled.Text>
            First, you shall contact the administrator of the system (use
            “contacts” tab or a chat on this website). You will receive the most
            detailed instruction of the game client operation, as well as the
            test access to the system, a bonus of connecting the game hall and
            the lowest percent of royalty in comparison with other game
            platforms.If there are no official representative in your region,
            the maintenance of your hall is carried out directly, through our
            technical support.
          </Styled.Text>

          <Styled.SubHeader1>
            And, finally, you wish to become our official representative in your
            region!
          </Styled.SubHeader1>

          <Styled.Text>
            If you wish to represent the GSDeluxe system in your region, to
            offer our services to game halls in your country and to receive the
            guaranteed percent from the profit of all halls connected by you –
            contact our technical support (“contacts” tab) and stipulate the
            terms of our partnership with the administrator. If you become the
            first representative in the region, you obtain an exclusivity
            guarantee from our system!In addition, you can open your own game
            hall on more favorable terms of partnership with GSDeluxe with a
            lower percent of royalty!
          </Styled.Text>
        </Styled.TermsBlock>
      </Styled.MainContentWrapper>
    </MainLayout>
  )
})

TermsPage.displayName = 'TermsPage'
