import styled, { css } from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxS,
} from '@/5_shared/styles'
import { Button, Icon, IconName } from '@/5_shared/ui'

export const Root = styled.div``

export const AuthModalRoot = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  justify-content: space-between;

  ${MediaMaxS`
    width: auto;
  `}
`

const rootsStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`

export const LoginRoot = styled.div`
  ${rootsStyles}
`

export const FormRoot = styled.form`
  ${rootsStyles}
`

export const SignUpRoot = styled.div`
  ${rootsStyles}
`

const blockStyles = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
`

export const HeadlineBlock = styled.div`
  ${blockStyles}
`

export const Headline = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const HeadlineUndertext = styled.div`
  color: ${defaultColorPalette.darkTypeMedium};
`

export const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: baseline;
  margin-bottom: 17.5px;
`

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})``

export const CheckboxLabel = styled.label<{ htmlFor: string }>`
  color: ${defaultColorPalette.darkTypeHigh};
  cursor: pointer;
`

export const SignUpButton = styled(Button)`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-grow: 1;
  // padding: 14px 24px;
  // cursor: pointer;
  // color: ${defaultColorPalette.white};
  // background-color: ${defaultColorPalette.darkPrimary600};
  // transition: all ${defaultTransitionTiming.fastest}s ease;
  // border-radius: 8px;
  //
  // &:hover {
  //   background-color: ${defaultColorPalette.darkPrimary700};
  // }
  //
  // &:active {
  //   background-color: ${defaultColorPalette.darkPrimary800};
  // }
`

export const LoginBtn = styled(Button)``

export const DashedBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const DashedBlock = styled.div`
  text-align: center;
  position: relative;
  color: ${defaultColorPalette.darkTypeMedium};
  z-index: 2;
  text-transform: uppercase;

  &:after {
    right: calc(100% + 8px);
  }

  &:before {
    left: calc(100% + 8px);
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    z-index: 1;
    background-color: ${defaultColorPalette.darkTypeMedium};
    width: 300px;
    top: calc(50% - 0.5px);
  }
`

export const BonusBlock = styled.div`
  color: ${defaultColorPalette.darkTypeMedium};
`

export const LoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  padding: 16px 32px;
  width: 100%;
  background-color: ${defaultColorPalette.darkPrimary600};
  border-radius: 8px;
  cursor: pointer;
  transition: all ${defaultTransitionTiming.faster}s ease;

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary700};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const LoginIcon = styled(Icon).attrs({
  name: IconName.Logout02,
  width: 24,
  height: 24,
  color: defaultColorPalette.white,
})``

export const LoginButtonText = styled.div`
  color: ${defaultColorPalette.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`

export const Link = styled.a<{ $align?: string }>`
  color: ${defaultColorPalette.darkPrimary600};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
  text-align: ${({ $align }) => ($align ? $align : 'right')};

  &:hover {
    color: ${defaultColorPalette.darkPrimary700};
  }
  &:active {
    color: ${defaultColorPalette.darkPrimary800};
  }
`
