export const enum IconName {
  Calendar01 = 'calendar-01',
  ChevronDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  CoinBroken = 'coin-broken',
  Mail01 = 'mail-01',
  Phone = 'phone',
  Telegram = 'telegram',
  Globe03 = 'globe-03',
  Search02 = 'search-02',
  Logout02 = 'logout-02',
  Logout01 = 'logout-01',
  X03 = 'x-03',
  EditContained = 'edit-contained',
  Wallet03 = 'wallet-03',
  Plus01 = 'plus-01',
  Download01 = 'download-01',
  UserProfile03 = 'user-profile-03',
  CopyRight = 'copy-right',
}
