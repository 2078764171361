import { domain } from './domain'

export const selectGenre = domain.createEvent<string | null>(
  'select game genre'
)

export const selectBrand = domain.createEvent<string | null>(
  'select game brand'
)

export const dropdownClick = domain.createEvent('click on dropdown')

export const openDropdown = domain.createEvent('open dropdown selection')
export const closeDropdown = domain.createEvent('close dropdown')
