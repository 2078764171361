import { FC, memo } from 'react'
import * as Styled from './styled'
import {
  $selectedMainCurrency,
  ProfileModalTab,
  selectProfileModalTab,
} from '../../../model'
import { useStore } from 'effector-react'
import { $fetchedBalancesList } from '@/2_widgets/profile-block'
import { UserService } from '@/7_services/user'
import { Balance } from '@/2_widgets/profile-block/types'
import { useTranslation } from 'react-i18next'

const handleSelectTopUp = () => {
  selectProfileModalTab(ProfileModalTab.TopUp)
}

export const ProfileCard: FC = () => {
  const balances = useStore($fetchedBalancesList)
  const { user } = useStore(UserService.getStore())
  const mainCurrency = useStore($selectedMainCurrency)
  const { t } = useTranslation()
  console.log({ mainCurrency, user })
  return (
    <Styled.ProfileCardRoot>
      <Styled.ProfileCardInfoWrapper>
        <Styled.ProfileCardInfo>
          <Styled.ProfileAvatar>
            <Styled.ProfileAvatarIcon />
          </Styled.ProfileAvatar>
          <Styled.ProfilePhone>
            {user?.user_info?.login_text}
          </Styled.ProfilePhone>
        </Styled.ProfileCardInfo>
      </Styled.ProfileCardInfoWrapper>

      <Styled.BalanceWrapper>
        <Styled.Balance>
          <Styled.BalanceAmount>
            {
              user?.currency_list.find(
                (curr) => mainCurrency === curr.CurrencyID
              )?.ShortName
            }
            &nbsp;
            {
              balances?.find(
                (balance: Balance) => balance?.currency_id === user?.currency_id
              )?.balance
            }
          </Styled.BalanceAmount>
          <Styled.OnBalance>{t('onBalance')}</Styled.OnBalance>
        </Styled.Balance>
        <Styled.TopUpButton onClick={handleSelectTopUp}>
          {t('topUp')}
        </Styled.TopUpButton>
      </Styled.BalanceWrapper>
    </Styled.ProfileCardRoot>
  )
}

ProfileCard.displayName = 'ProfileCard'
