import styled from 'styled-components'
import { defaultColorPalette, MediaMaxM, MediaMaxS } from '@/5_shared/styles'
import { AutisticText } from '@/5_shared/ui'

export const Root = styled.div`
  position: relative;
`

export const MainBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
`

export const TermsBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const MainHeader = styled(AutisticText)`
  margin-bottom: 40px;
  font-size: 42px;
`

export const SubHeader1 = styled.div`
  color: ${defaultColorPalette.darkTypeHigh};
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
`

export const SubHeader2 = styled.div`
  color: ${defaultColorPalette.darkPrimary500};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

export const Text = styled.div`
  color: ${defaultColorPalette.darkTypeHigh};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
`

export const TextEnumerable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    bottom: 0;
    top: 36px;
    left: 15px;
    background-color: ${defaultColorPalette.darkNeutrals300};
    z-index: -1;
  }
`

export const TextCount = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${defaultColorPalette.darkPrimary600};
  color: ${defaultColorPalette.darkTypeHigh};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

export const SpecialOfferTopupWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;

  ${MediaMaxS`
    flex-direction: column;
  `}
`

export const MainContentWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 72px;
  padding-right: 36px;
  padding-top: 100px;

  ${MediaMaxM`
    padding: 50px 20px;
  `}
`
