import * as Styled from './styled'
import { Digit } from './digit'

export interface ICounterProps {
  value: number
  duration?: number
  cycle?: number
}

export const Digits = ({ value, ...props }: ICounterProps) => {
  const digits = value.toLocaleString('ru-RU').split('')
  return (
    <Styled.Amount style={{ display: 'flex', justifyContent: 'center' }}>
      {digits.map((digit, index) =>
        !isNaN(parseInt(digit)) ? (
          <Digit
            number={parseInt(digit)}
            key={index}
            cycle={index}
            {...props}
          />
        ) : (
          <Styled.AmountSymbolWrapper key="dot">
            <Styled.AmountSymbol>.</Styled.AmountSymbol>
          </Styled.AmountSymbolWrapper>
        )
      )}
    </Styled.Amount>
  )
}

Digits.displayName = 'Digits'
