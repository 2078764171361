import { FC, useRef, useEffect } from 'react'
import * as Styled from './styled'
import { GamesService } from '@/7_services/games'
import { useStore } from 'effector-react'
import { selectGame } from '@/7_services/games/model/events'

const IFrame: FC = () => {
    const { actualGame } = useStore(
        GamesService.getStore()
    )
    const iFrameRef = useRef(null)
    const massageHandler = (event: MessageEvent) => {
        if (
            event.data === 'api_exit' ||
            event.data === 'api_terminate' ||
            event.data === 'api_error'
        ) {
            selectGame(null)
        }
    }

    const getDimension = () => {
        setDimension(() => ({
            width: window.innerWidth,
            height: window.innerHeight,
        }))
    }

    useEffect(() => {
        window.addEventListener('message', massageHandler)
        window.addEventListener('resize', getDimension)

        return () => {
            window.removeEventListener('message', massageHandler)
            window.removeEventListener('resize', getDimension)
        }
    }, [])

    return actualGame ? (
        <Styled.Frame
            srcDoc={actualGame}
            width="100%"
            height="100%"
            allow="fullscreen"
            allowFullScreen
            ref={iFrameRef}
        ></Styled.Frame>
    ) : null
}

export default IFrame

IFrame.displayName = 'IFrame'
