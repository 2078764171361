export const Config = {
  tg: '@GSDeluxeBot',
  email: 'support@gsdeluxe.com',
  phone: '+1-646-66-41-911',
}

export const isDevelopment = () => {
  return (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV !== 'production'
  )
}
