import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
} from '@/5_shared/styles'
import { Icon, IconName } from '../icon'

export const Root = styled.div``

export const DefaultListStyleComponent = styled.div<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  position: absolute;
  top: 40px;
  left: 5px;
  right: 5px;
  background-color: ${defaultColorPalette.darkPrimary700};
  border-radius: 6px;
  padding-bottom: 6px;
  padding-top: 6px;
  transition: opacity ${defaultTransitionTiming.faster}s ease;
  z-index: ${BASE_Z_INDEX.DROPDOWN};
`

export const DefaultItemStyleComponent = styled.div<{
  onClick: (item: string) => void
  $isSelected: boolean
}>`
  color: ${defaultColorPalette.white};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  transition: background-color ${defaultTransitionTiming.fastest}s ease;
  user-select: none;

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? defaultColorPalette.darkPrimary800
      : defaultColorPalette.darkPrimary700};

  &:hover {
    background-color: ${defaultColorPalette.white};
    color: ${defaultColorPalette.black};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const DropdownButton = styled.div`
  color: white;
  cursor: pointer;
  font-weight: 500;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

const iconStyles = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const ChevronDownIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronDown,
})`
  pointer-events: none;
`

export const ChevronUpIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronUp,
})`
  pointer-events: none;
`
