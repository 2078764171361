import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { $isBonusBannerShown, closeBanner } from '../model'
import { useStore } from 'effector-react'

interface BonusBannerProps {
  className?: string
}

const handleCloseBanner = () => {
  closeBanner()
}

export const BonusBanner: FC<BonusBannerProps> = memo(
  ({ className }: BonusBannerProps) => {
    const isShown = useStore($isBonusBannerShown)

    if (!isShown) {
      return null
    }

    return (
      <Styled.RootBorder className={className}>
        <Styled.Root>
          <Styled.Bonus>Bonus</Styled.Bonus>
          <Styled.TermsBlock>
            <Styled.Terms1>
              After registering on the site, your account is automatically
              replenished with 5,000 credits.
            </Styled.Terms1>
            <Styled.Terms2>
              SPECIAL OFFER! <br />
              70,000 credits at a price of 5 euros, 150,000 credits at a price
              of 10 euros.
            </Styled.Terms2>
          </Styled.TermsBlock>
          <Styled.Image />
          <Styled.CloseButton onClick={handleCloseBanner} />
        </Styled.Root>
      </Styled.RootBorder>
    )
  }
)

BonusBanner.displayName = 'BonusBanner'
