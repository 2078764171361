// here will be wrappers for localization, themes, time local and so on
import React, { FC, memo } from 'react'
import { Outlet } from 'react-router-dom'

interface ContextProviderProps {}

export const ContextProvider: FC<ContextProviderProps> = memo(
  ({}: ContextProviderProps) => {
    return <Outlet />
  }
)

ContextProvider.displayName = 'ContextProvider'
