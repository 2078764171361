import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  MediaMaxL,
  MediaMaxM,
  MediaMaxS,
} from '@/5_shared/styles'
import { LogoImage } from '@/5_shared/assets'
import { Icon, IconName } from '@/5_shared/ui'

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 120px;
  background-color: ${defaultColorPalette.darkSurfaceTertiary};
  box-shadow: 0 16px 32px 0 #070a13;
  position: relative;
  z-index: ${BASE_Z_INDEX.MAIN_SIDEBAR + 1};

  ${MediaMaxL`
     padding: 16px 60px;
  `}

  ${MediaMaxS`
   flex-direction: column;
   gap: 16px;
  `}
`

export const Copyright = styled.div`
  white-space: nowrap;
  color: ${defaultColorPalette.darkTypeMedium};
`

export const Contacts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 26px;

  ${MediaMaxM`
     flex-direction: column;
     gap: 16px;
  `}
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`

const iconStyled = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const IconEmail = styled(Icon).attrs({
  ...iconStyled,
  name: IconName.Mail01,
})``

export const IconSkype = styled(Icon).attrs({
  ...iconStyled,
  name: IconName.Phone,
})``

export const IconPhone = styled(Icon).attrs({
  ...iconStyled,
  name: IconName.Phone,
})``
export const TelegramIcon = styled(Icon).attrs({
  ...iconStyled,
  name: IconName.Telegram,
})``

export const Contact = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${defaultColorPalette.white};
  cursor: pointer;
`

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  width: 116px;
  height: 90px;
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`
