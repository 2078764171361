import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import { $topUpSelectedCurrency } from '../../../model'
import { CurrencyStep } from './currency-step'
import { MainStep } from './main-step'

interface TopUpTabProps {}

export const TopUpTab: FC<TopUpTabProps> = memo(({}: TopUpTabProps) => {
  const topUpSelectedCurrency = useStore($topUpSelectedCurrency)

  return (
    <Styled.Root>
      {topUpSelectedCurrency ? <CurrencyStep /> : <MainStep />}
    </Styled.Root>
  )
})

TopUpTab.displayName = 'TopUpTab'
