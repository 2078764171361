import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
  hexToRgbA,
  MediaMaxM,
} from '@/5_shared/styles'
import { Link } from 'react-router-dom'
import { LogoImage } from '@/5_shared/assets'
import { LoginButton as LoginButtonUi } from '@/2_widgets/auth-modal'
import { ProfileBlock as ProfileBlockUi } from '@/2_widgets/profile-block'

export const Root = styled.nav`
  background-color: ${defaultColorPalette.darkPrimary800};
  position: fixed;
  z-index: ${BASE_Z_INDEX.NAVBAR};
  left: 0;
  right: 0;

  height: 48px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 250px;

  ${MediaMaxM`
    position: relative;
    padding-left: 0;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    z-index: ${BASE_Z_INDEX.DROPDOWN};
  `}
`

export const MenuBlock = styled.div`
  display: flex;
  gap: 0 16px;

  ${MediaMaxM`
    justify-content: space-around;
    gap: 0;
  `}
`

export const NavLink = styled(Link)`
  color: ${defaultColorPalette.lightPrimary300};
  text-decoration: none;

  &:hover {
    color: ${hexToRgbA(defaultColorPalette.lightPrimary300, 0.7)};
  }

  ${MediaMaxM`
    padding: 11px;
  `}
`

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${defaultColorPalette.darkSurfaceTertiary};
  z-index: ${BASE_Z_INDEX.DROPDOWN};
  justify-content: space-evenly;
`

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const BottomBottomRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
`

export const LogoLink = styled(Link)`
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  background-color: ${defaultColorPalette.darkPrimary700};
`

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  width: 116px;
  height: 90px;
  background-color: ${defaultColorPalette.darkPrimary700};
`

export const Contacts = styled.div`
  display: flex;
  gap: 0 26px;
  color: #fff;
  white-space: nowrap;
`

export const Contact = styled.a`
  size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  color: ${defaultColorPalette.white};
  transition: all ${defaultTransitionTiming}s ease;
  text-decoration: none;
`

export const LanguageSelectorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const LoginButtonWrapper = styled.div`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
`

export const LoginButton = styled(LoginButtonUi)`
  padding: 10px;
`

export const ProfileBlock = styled(ProfileBlockUi)``
