// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GXNWHrusArcQkI5TADkW {
    width: 45px !important;
    padding: 6px !important;
    border: none !important;
    background-color: transparent !important;
}

.GXNWHrusArcQkI5TADkW {
    background-color: transparent !important;
}

.GXNWHrusArcQkI5TADkW > div {
    background-color: transparent !important;
}

.IedV7mb8WSWfVBEnzBBx {
    width: 100% !important;
    border: 1.5px #384857 solid !important;
    background-color: transparent !important;
    color: white !important;
    opacity: 0.7;
}

.IedV7mb8WSWfVBEnzBBx:focus {
    opacity: 1;
    box-shadow: 0 0 10px -3px #5F00FF;
}

.g5v45fXXsaTmJJx4mR7G {
}
`, "",{"version":3,"sources":["webpack://./src/5_shared/ui/phone-input/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,sBAAsB;IACtB,sCAAsC;IACtC,wCAAwC;IACxC,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,iCAAiC;AACrC;;AAEA;AACA","sourcesContent":[".buttonClass {\n    width: 45px !important;\n    padding: 6px !important;\n    border: none !important;\n    background-color: transparent !important;\n}\n\n.buttonClass {\n    background-color: transparent !important;\n}\n\n.buttonClass > div {\n    background-color: transparent !important;\n}\n\n.inputClass {\n    width: 100% !important;\n    border: 1.5px #384857 solid !important;\n    background-color: transparent !important;\n    color: white !important;\n    opacity: 0.7;\n}\n\n.inputClass:focus {\n    opacity: 1;\n    box-shadow: 0 0 10px -3px #5F00FF;\n}\n\n.dropdownClass {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonClass": `GXNWHrusArcQkI5TADkW`,
	"inputClass": `IedV7mb8WSWfVBEnzBBx`,
	"dropdownClass": `g5v45fXXsaTmJJx4mR7G`
};
export default ___CSS_LOADER_EXPORT___;
