import React, { FC, memo, ReactNode } from 'react'
import * as Styled from './styled'

interface LabeledInputProps {
  label?: string
  children: ReactNode
  className?: string
  rightContent?: ReactNode
  onRightButtonClick?: () => void
}

export const LabeledInput: FC<LabeledInputProps> = memo(
  ({
    label,
    children,
    className,
    rightContent,
    onRightButtonClick,
  }: LabeledInputProps) => {
    return (
      <Styled.Root className={className}>
        <Styled.Label>{label}</Styled.Label>
        <Styled.InputWrapper>
          {children}

          {rightContent && (
            <Styled.RightButton onClick={onRightButtonClick}>
              {rightContent}
            </Styled.RightButton>
          )}
        </Styled.InputWrapper>
      </Styled.Root>
    )
  }
)

LabeledInput.displayName = 'LabeledInput'
