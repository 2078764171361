import React, { FC, memo } from 'react'
import * as Styled from './styled'
import { IconName } from './types'

interface IconProps {
  name?: IconName
  color?: string
  fill?: string
  width?: number
  height?: number
  className?: string
  viewBox?: string
}

export const Icon: FC<IconProps> = memo(
  ({
    name,
    color,
    fill,
    height = 24,
    width = 24,
    className,
    viewBox,
  }: IconProps) => {
    if (name === undefined) {
      return null
    }

    const SvgIcon = require('./assets/' + name + '.svg').default

    return (
      <Styled.SvgWrapper className={className} color={color} fill={fill}>
        <SvgIcon
          width={width}
          height={height}
          viewBox={viewBox ? viewBox : '0 0 24 24'}
        />
      </Styled.SvgWrapper>
    )
  }
)

Icon.displayName = 'Icon'
