import { FC, memo } from 'react'
import * as Styled from './styled'
import { Currency } from '@/4_entities/currency'
import { CurrencyCard } from '@/4_entities/currency/ui'
import {
  $selectedMainCurrency,
  closeProfileModal,
  setMainCurrency,
} from '../../../model'
import { useStore } from 'effector-react'
import { Button, ButtonType, ButtonVariant } from '@/5_shared/ui'
import { UserService } from '@/7_services/user'
import { $fetchedBalancesList } from '@/2_widgets/profile-block'
import { useTranslation } from 'react-i18next'

const handleSetSelected = (id: number) => {
  setMainCurrency(id)
}

const handleCloseModal = () => {
  closeProfileModal()
}

export const CreditsTab: FC = memo(() => {
  const mainCurrency = useStore($selectedMainCurrency)
  const balances = useStore($fetchedBalancesList)
  const { user } = useStore(UserService.getStore())
  const { t } = useTranslation()

  return (
    <Styled.Root>
      <Styled.Currencies>
        <Styled.SelectCurrencyWrapper>
          <Styled.SelectCurrencyTitle>
            {t('currency')}
          </Styled.SelectCurrencyTitle>
          <Styled.SelectCurrencyText>
            {t('currencyHeader')}
          </Styled.SelectCurrencyText>
        </Styled.SelectCurrencyWrapper>
        {user?.currency_list.map((cur: Currency) => {
          return (
            <Styled.CurrencyCardWrapper key={cur.CurrencyID}>
              <CurrencyCard
                onClick={() => handleSetSelected(cur.CurrencyID)}
                currency={cur}
                amount={
                  balances.find(
                    (balance) => balance?.currency_id === cur.CurrencyID
                  )?.balance
                }
                isSelected={mainCurrency === cur.CurrencyID}
              />
            </Styled.CurrencyCardWrapper>
          )
        })}
      </Styled.Currencies>

      <Styled.ButtonsWrapper>
        <Button
          type={ButtonType.Neutral}
          variant={ButtonVariant.Inverted}
          fluid
          onClick={handleCloseModal}
        >
          {t('buttons.close')}
        </Button>
        {/* <Button onClick={handleCloseModal} fluid>
          {t('buttons.save')}
        </Button> */}
      </Styled.ButtonsWrapper>
    </Styled.Root>
  )
})

CreditsTab.displayName = 'CreditsTab'
