import { MediaMaxS } from '@/5_shared/styles'
import styled from 'styled-components'

export const Root = styled.div`
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  justify-content: space-between;

  ${MediaMaxS`
    width: auto;
  `}
`
