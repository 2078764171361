import { domain } from './domain'
import { api } from '../api'

export const getAllGames = domain.createEvent('try to get all games')
export const getAllGamesFx = domain.createEffect('get all games', {
  handler: api.fetchGamesList,
})
export const getGameById = domain.createEffect('get game by ID', {
  handler: api.fetchGameById,
})
export const selectGame = domain.createEvent('select game')
