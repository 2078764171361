import { ChangeEvent, FC, FormEvent, memo, SyntheticEvent, useState } from 'react'
import * as Styled from '../styled'
import {
  Input,
  // EmailInput,
  LabeledInput,
  PasswordInput,
  PhoneInput,
} from '@/5_shared/ui'
import {
  $confirmPasswordInput,
  $passwordInput,
  login,
  phoneChangeEvent,
  passwordChangeEvent,
  confirmPasswordChangeEvent,
  $codeInput,
  $isVerifyCodeButtonDisabled,
  $isVerifyRequest,
  $phoneInput,
  codeChangeEvent,
  $isSendPhoneButtonDisabled,
} from '../../model'
import { useStore } from 'effector-react'
import { UserService } from '@/7_services/user'
import { useTranslation } from 'react-i18next'

interface LoginTabProps { }

const handleLogin = () => {
  login()
}

const phoneInputHandler = (phone: string) => {
  phoneChangeEvent(phone)
}

const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
  confirmPasswordChangeEvent(event.target.value)
}

const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
  passwordChangeEvent(event.target.value)
}

const codeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
  codeChangeEvent(event.target.value)
}

export const LoginTab: FC<LoginTabProps> = memo(({ }: LoginTabProps) => {
  const phone = useStore($phoneInput)
  const password = useStore($passwordInput)
  const confirmPassword = useStore($confirmPasswordInput)
  const code = useStore($codeInput)
  const disabledSavePassword = useStore($isVerifyCodeButtonDisabled)
  const disabledRequestCode = useStore($isSendPhoneButtonDisabled)
  const isVerifyRequest = useStore($isVerifyRequest)
  const { isLoginPending } = useStore(UserService.getStore())
  const { t } = useTranslation()
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false)
  const verifyCodeHandler = () => {
    if (!disabledSavePassword) {
      UserService.passwordResetVerify({
        user_login: `+${phone}`,
        verify_code: code,
        new_password: password,
      })
    }
  }

  const handleRequestCode = () => {
    UserService.passwordReset({
      user_login: `+${phone}`,
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (showResetPassword) {
      if (isVerifyRequest) {
        verifyCodeHandler()
      } else {
        handleRequestCode()
      }
    } else {
      handleLogin()
    }
  }

  return (
    <Styled.LoginRoot>
      <Styled.HeadlineBlock>
        <Styled.Headline>{t('loginSignupWindow.welcomeBack')}</Styled.Headline>
        <Styled.HeadlineUndertext>
          {showResetPassword
            ? t('loginSignupWindow.headerResetPasswordLabel')
            : t('loginSignupWindow.headerLoginLabel')}
        </Styled.HeadlineUndertext>
      </Styled.HeadlineBlock>
      <Styled.FormRoot onSubmit={handleSubmit}>
        <LabeledInput label={t('loginSignupWindow.phone')}>
          <PhoneInput value={phone} onChange={phoneInputHandler} />
        </LabeledInput>

        {showResetPassword ? (
          <>
            {isVerifyRequest ? (
              <>
                <LabeledInput label={t('profileTab.newPassword')}>
                  <PasswordInput
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </LabeledInput>
                <LabeledInput label={t('profileTab.confirmPassword')}>
                  <PasswordInput
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                </LabeledInput>
                <LabeledInput label={t('loginSignupWindow.code')}>
                  <Input value={code} onChange={codeInputHandler} />
                </LabeledInput>
                <Styled.LoginBtn
                  disabled={disabledSavePassword}
                  onClick={verifyCodeHandler}
                  nativeType="submit"
                >
                  {t('buttons.save')}
                </Styled.LoginBtn>
              </>
            ) : (
              <Styled.LoginBtn
                onClick={handleRequestCode}
                disabled={disabledRequestCode}
                nativeType="submit"
              >
                {t('buttons.send')}
              </Styled.LoginBtn>
            )}
          </>
        ) : (
          <>
            <LabeledInput label={t('loginSignupWindow.password')}>
              <PasswordInput
                disabled={isLoginPending}
                value={password}
                onChange={handlePasswordChange}
              />
            </LabeledInput>
            <Styled.LoginBtn
              disabled={isLoginPending}
              isPending={isLoginPending}
              onClick={handleLogin}
              nativeType="submit"
            >
              {t('login')}
            </Styled.LoginBtn>
          </>
        )}
      </Styled.FormRoot>

      {showResetPassword ? (
        <Styled.Link $align="left" onClick={() => setShowResetPassword(false)}>
          {t('loginSignupWindow.back')}
        </Styled.Link>
      ) : (
        <Styled.Link onClick={() => setShowResetPassword(true)}>
          {t('loginSignupWindow.resetPassword')}
        </Styled.Link>
      )}
    </Styled.LoginRoot>
  )
})

LoginTab.displayName = 'LoginTab'
