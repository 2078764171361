import React, { FC, memo, ReactNode } from 'react'
import ReactModal from 'react-modal'
import { BASE_Z_INDEX } from '@/5_shared/styles'
import { ModalPaper } from '@/5_shared/ui/modal/modal-paper'

interface ModalProps {
  isOpen: boolean
  className?: string
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  preventScroll?: boolean
  children: ReactNode
  onClose: () => void
  overlayStyles?: object
  defaultView?: boolean
  renderCloseCross?: boolean
}

const overlayStyle = {
  position: 'fixed',
  zIndex: BASE_Z_INDEX.MODAL,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'scroll',
  backgroundColor: 'RGBA(32,40,49,0.8)', // rgba(--dark-neutrals-800, 0.8)
}

export const Modal: FC<ModalProps> = memo(
  ({
    isOpen,
    className,
    shouldCloseOnOverlayClick = true,
    shouldCloseOnEsc = true,
    preventScroll = false,
    children,
    onClose,
    overlayStyles = overlayStyle,
    defaultView = false,
    renderCloseCross = false,
  }: ModalProps) => {
    return (
      <ReactModal
        isOpen={isOpen}
        className={className}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        preventScroll={preventScroll}
        onRequestClose={onClose}
        ariaHideApp={false}
        style={{
          overlay: overlayStyles,
          content: {
            position: 'initial',
            top: 'initial',
            left: 'initial',
            right: 'initial',
            bottom: 'initial',
            border: 'initial',
            background: 'initial',
            overflow: 'initial',
            WebkitOverflowScrolling: 'touch',
            borderRadius: 'initial',
            outline: 'initial',
            padding: 'initial',
            maxHeight: 660,
          },
        }}
      >
        {defaultView ? (
          <ModalPaper onClose={onClose} renderCloseCross={renderCloseCross}>
            {children}
          </ModalPaper>
        ) : (
          children
        )}
      </ReactModal>
    )
  }
)

Modal.displayName = 'Modal'
