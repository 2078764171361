import styled from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxL,
  MediaMaxM,
  MediaMaxS,
  MediaMaxXs,
} from '@/5_shared/styles'
import { AutisticText, Icon, IconName, Input } from '@/5_shared/ui'
import { SearchInput as SearchInputUi } from './search-input'

export const Root = styled.div``

export const SearchWrapper = styled.div`
  display: flex;
  position: relative;

  ${MediaMaxS`
    width: 100%;
  `}
`

export const SearchIcon = styled(Icon).attrs({
  name: IconName.Search02,
  width: 24,
  height: 24,
  color: defaultColorPalette.darkTypeMedium,
})`
  position: absolute;
  top: 10px;
  left: 12px;
`

export const Search = styled(Input)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 12px;
  padding-left: 44px;
  border-radius: 24px;
  width: 240px;
  border: 1px solid ${defaultColorPalette.darkNeutrals400};
  color: ${defaultColorPalette.darkTypeMedium};
  outline: none;
  background-color: transparent;
  transition: all ${defaultTransitionTiming.faster}s ease;

  &:focus {
    background-color: ${defaultColorPalette.darkSurfaceTertiary};
  }

  ${MediaMaxS`
    width: 100%;
  `}
`

export const GamesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  padding-bottom: 100px;

  ${MediaMaxM`
    gap: 32px;
  `}

  ${MediaMaxS`
    gap: 24px;
  `}

  ${MediaMaxXs`
    gap: 24px;
  `}
`

export const HeadlineBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  flex-wrap: wrap;

  ${MediaMaxS`
    flex-wrap: wrap;
    gap: 24px;
  `}
`

export const SearchInput = styled(SearchInputUi)``

export const Title = styled(AutisticText)`
  font-size: 42px;
  white-space: nowrap;
  color: ${defaultColorPalette.white};
`

export const GameCardWrapper = styled.a`
  flex-basis: calc(33% - 20px);

  ${MediaMaxL`
    flex-basis: calc(50% - 16px);
    flex-grow: 1;
  `}

  ${MediaMaxM`
    flex-basis: calc(33% - 24px);
    flex-grow: 0;
  `}

  ${MediaMaxS`
    flex-basis: calc(50% - 12px);
    flex-grow: 0;
  `}

  ${MediaMaxXs`
    flex-basis: 100%;
  `}
`

export const NoResults = styled.div`
  font-size: 24px;
  color: ${defaultColorPalette.white};
`

export const Frame = styled.iframe`
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #000;
`
