import { domain } from './domain'
import { split } from 'effector'
import {
  selectBrand,
  selectGenre,
  openDropdown,
  closeDropdown,
  dropdownClick,
} from './events'

export const $selectedGenre = domain
  .createStore<string | null>(null)
  .on(selectGenre, (_, payload) => payload)
  .reset(selectBrand)
export const $selectedBrand = domain
  .createStore<string | null>(null)
  .on(selectBrand, (_, payload) => payload)
  .reset(selectGenre)

export const $isDropdownOpened = domain
  .createStore<boolean>(false)
  .on(openDropdown, () => true)
  .reset(closeDropdown)

// effector split has horrible support in ts
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
split({
  source: dropdownClick,
  match: {
    open: $isDropdownOpened.map((val) => !val),
    close: $isDropdownOpened.map((val) => val),
  },
  cases: {
    open: openDropdown,
    close: closeDropdown,
  },
})
