import { toast } from 'react-toastify'

export class NotificationService {
  static success(message: string) {
    return toast.success(message)
  }

  static error(message: string) {
    return toast.error(message)
  }

  static info(message: string) {
    return toast.info(message)
  }

  static warning(message: string) {
    return toast.warning(message)
  }
}
