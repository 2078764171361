import styled from 'styled-components'
import { defaultColorPalette, defaultTransitionTiming } from '@/5_shared/styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  // z-index: 2;

  & input {
    padding: 10px 12px;
    border-radius: 4px;
    border: 1.5px ${defaultColorPalette.darkNeutrals400} solid;
    outline: none;
    background-color: transparent;
    color: ${defaultColorPalette.white};
    font-size: 16px;
    width: 100%;
  }
`

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`

export const RightButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-style: solid;
  border-right-width: 1.5px;
  border-top-width: 1.5px;
  border-bottom-width: 1.5px;
  border-left: 0;
  border-color: ${defaultColorPalette.darkNeutrals400};
  cursor: pointer;
  transition: all ${defaultTransitionTiming.fastest}s ease;
  background-color: ${defaultColorPalette.darkPrimary600};
  color: ${defaultColorPalette.white};

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary700};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`
