import { FC, memo } from 'react'
import * as Styled from './styled'
import { Game } from '@/7_services/games'

export const GameCard: FC<Game> = memo((props: Game) => {
  return (
    <Styled.Root>
      <Styled.ImageBlock>
        <Styled.Image src={`${props.baseIconUrl}${props.GameIconUrl}`} />
      </Styled.ImageBlock>
      <Styled.InfoBlock>
        <Styled.Title>{props.Name}</Styled.Title>
        <Styled.Badges>
          <Styled.Badge>{props.Genre}</Styled.Badge>
          <Styled.Badge>{props.Platform}</Styled.Badge>
        </Styled.Badges>
      </Styled.InfoBlock>
    </Styled.Root>
  )
})

GameCard.displayName = 'GameCard'
