import { ChangeEvent, FC, FormEvent, memo } from 'react'
import * as Styled from '../styled'
import { Input, LabeledInput, PasswordInput, PhoneInput } from '@/5_shared/ui'
import {
  $checkboxTermsChecked,
  $confirmPasswordInput,
  $isSignUpButtonDisabled,
  $passwordInput,
  $phoneInput,
  $serialInput,
  checkTermCheckbox,
  confirmPasswordChangeEvent,
  passwordChangeEvent,
  phoneChangeEvent,
  serialChangeEvent,
  signUp,
  $isVerifyRequest,
  $isVerifyCodeButtonDisabled,
  codeChangeEvent,
  $codeInput,
} from '../../model'
import { useStore } from 'effector-react'
import { UserService } from '@/7_services/user'
import { useTranslation } from 'react-i18next'

interface SignUpTabProps { }

const phoneInputHandler = (data: string) => {
  phoneChangeEvent(data)
}

const passwordInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
  passwordChangeEvent(event.target.value)
}

const confirmPasswordInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
  confirmPasswordChangeEvent(event.target.value)
}

const serialInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
  serialChangeEvent(event.target.value)
}

const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
  checkTermCheckbox(event.target.checked)
}

const codeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
  codeChangeEvent(event.target.value)
}

export const SignUpTab: FC<SignUpTabProps> = memo(({ }: SignUpTabProps) => {
  const phone = useStore($phoneInput)
  const password = useStore($passwordInput)
  const confirmPassword = useStore($confirmPasswordInput)
  const serial = useStore($serialInput)
  const code = useStore($codeInput)
  const checked = useStore($checkboxTermsChecked)
  const disabled = useStore($isSignUpButtonDisabled)
  const disabledCode = useStore($isVerifyCodeButtonDisabled)
  const isVerifyRequest = useStore($isVerifyRequest)
  const { t } = useTranslation()
  const { isSignUpPromoPending } = useStore(UserService.getStore())
  const signUpHandler = () => {
    if (!disabled) {
      signUp()
    }
  }

  const verifyCodeHandler = () => {
    if (!disabledCode) {
      UserService.codeVerify({
        user_login: `+${phone}`,
        verify_code: code,
      })
    }
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isVerifyRequest) {
      verifyCodeHandler()
    } else {
      signUpHandler()
    }
  }
  return (
    <Styled.SignUpRoot>
      <Styled.HeadlineBlock>
        <Styled.Headline>{t('loginSignupWindow.welcome')}</Styled.Headline>
        <Styled.HeadlineUndertext>
          {isVerifyRequest
            ? t('loginSignupWindow.headerVerifyLabel')
            : t('loginSignupWindow.headerSignUpLabel')}
        </Styled.HeadlineUndertext>
      </Styled.HeadlineBlock>
      <Styled.FormRoot onSubmit={handleSubmit}>
        {isVerifyRequest ? (
          <>
            <LabeledInput label={t('loginSignupWindow.code')}>
              <Input value={code} onChange={codeInputHandler} />
            </LabeledInput>

            <Styled.SignUpButton
              disabled={disabledCode}
              onClick={verifyCodeHandler}
              nativeType="submit"
            >
              {t('loginSignupWindow.verify')}
            </Styled.SignUpButton>
          </>
        ) : (
          <>
            <LabeledInput label={t('loginSignupWindow.phone')}>
              <PhoneInput value={phone} onChange={phoneInputHandler} />
            </LabeledInput>

            <LabeledInput label={t('loginSignupWindow.password')}>
              <PasswordInput value={password} onChange={passwordInputHandler} />
            </LabeledInput>

            <LabeledInput label={t('loginSignupWindow.passwordConfirm')}>
              <PasswordInput
                value={confirmPassword}
                onChange={confirmPasswordInputHandler}
              />
            </LabeledInput>

            <LabeledInput label={t('loginSignupWindow.promo')}>
              <Input value={serial} onChange={serialInputHandler} />
            </LabeledInput>

            <Styled.CheckboxBlock>
              <Styled.Checkbox
                checked={checked}
                onChange={checkHandler}
                id="terms_and_conditions"
              />
              <Styled.CheckboxLabel htmlFor="terms_and_conditions">
                {t('loginSignupWindow.agree')}
              </Styled.CheckboxLabel>
            </Styled.CheckboxBlock>

            <Styled.SignUpButton
              isPending={isSignUpPromoPending}
              disabled={disabled}
              onClick={signUpHandler}
              nativeType="submit"
            >
              {t('loginSignupWindow.signUp')}
            </Styled.SignUpButton>
          </>
        )}
      </Styled.FormRoot>
    </Styled.SignUpRoot>
  )
})

SignUpTab.displayName = 'SignUpTab'
