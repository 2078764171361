import React, { ChangeEvent, FC, memo } from 'react'
import * as Styled from './styled'

interface InputProps {
  value: string | number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  type?: string
  step?: string
  min?: string
  pattern?: string
  required?: boolean
  autocomplete?: string
  name?: string
}

export const Input: FC<InputProps> = memo(function Input({
  value,
  onChange,
  placeholder,
  className,
  disabled,
  type = 'text',
  step,
  min,
  pattern,
  required,
  autocomplete,
  name,
}: InputProps) {
  return (
    <Styled.Root
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      className={className}
      disabled={disabled}
      step={step}
      min={min}
      pattern={pattern}
      required={required}
      autoComplete={autocomplete}
      name={name}
    />
  )
})

Input.displayName = 'Input'
