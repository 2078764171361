import { FC, memo, useEffect, useRef } from 'react'
import * as Styled from './styled'
import { JackpotItem } from './jackpot-item'
import { useStore } from 'effector-react'
import { getJackpotsFx, $fetchedJackPotList } from '../model'
import { JackPot } from '../types'
import { useTranslation } from 'react-i18next'

interface JackpotsProps {
  className?: string
}

export const Jackpots: FC<JackpotsProps> = memo(
  ({ className }: JackpotsProps) => {
    const { t } = useTranslation()
    useEffect(() => {
      getJackpotsFx()
      const jackpotTimer = setInterval(() => {
        getJackpotsFx()
      }, 10000)
      return () => {
        clearInterval(jackpotTimer)
      }
    }, [])

    const rightBlockRef = useRef(null)
    const rightBlockListRef = useRef(null)
    const handleScroll = () => {
      if (rightBlockRef?.current && rightBlockListRef?.current) {
        const rightBlock = rightBlockRef.current
        const rightBlockList = rightBlockListRef.current
        const list = rightBlockList.getBoundingClientRect()
        if (
          window.scrollY > 220 &&
          list.bottom <= window.innerHeight &&
          list.bottom - list.top > window.innerHeight
        ) {
          rightBlockList.style.position = 'fixed'
          rightBlock.style.right = 0
          rightBlock.style.width = '352px'
          rightBlockList.style.bottom = '30px'
        } else if (
          list.top <= 60 &&
          window.scrollY > 220 &&
          list.bottom - list.top <= window.innerHeight
        ) {
          rightBlock.style.right = 0
          rightBlock.style.width = '352px'
          rightBlock.style.height = '110vh'
          rightBlock.style.position = 'fixed'
          rightBlock.style.top = '-80px'
        } else if (window.scrollY <= 220) {
          rightBlockList.style.position = 'static'
          rightBlock.style.top = 'auto'
          rightBlock.style.position = 'static'
        }
      }
    }
    useEffect(() => {
      if (window.innerWidth >= 1024) {
        window.addEventListener('scroll', handleScroll)
      }
      return () => {
        if (window.innerWidth >= 1024) {
          window.removeEventListener('scroll', handleScroll)
        }
      }
    }, [window.innerWidth])

    const data = useStore($fetchedJackPotList)
    return (
      <Styled.Root className={className} ref={rightBlockRef}>
        <Styled.Title>{t('jackpot')}</Styled.Title>

        <Styled.JackpotsList ref={rightBlockListRef}>
          {data.map((jackpot: JackPot) => (
            <JackpotItem jackpot={jackpot} key={jackpot.JackpotID} />
          ))}
        </Styled.JackpotsList>
      </Styled.Root>
    )
  }
)

Jackpots.displayName = 'Jackpots'
