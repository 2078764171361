import { api } from '../api'
import { domain } from './domain'
import { ProfileModalTab } from './types'
import { Currency } from '@/4_entities/currency'

export const openProfileModal = domain.createEvent<ProfileModalTab | void>(
  'open profile modal'
)

export const closeProfileModal = domain.createEvent('close profile modal')

export const selectProfileModalTab = domain.createEvent<ProfileModalTab>(
  'select profile modal tab'
)

// todo to user profile
export const nicknameChange = domain.createEvent<string>(
  'on input nickname value'
)

// todo to user profile
export const firstLastNameChange = domain.createEvent<string>(
  'on input first and last name'
)

// todo to user profile
export const setMainCurrency = domain.createEvent<number>(
  'on set main currency in profile'
)

export const setMainCurrencyFx = domain.createEffect<number>(
  'on set main currency in profile',
  { handler: api.setMainCurrency }
)

export const setTopUpCurrency = domain.createEvent<number | null>(
  'set top up tab currency'
)

export const setWithdrawCurrency = domain.createEvent<number | null>(
  'set withdraw tab currency'
)

export const setWithdrawDestinationWallet = domain.createEvent<string>(
  'on input withdraw currency wallet'
)

export const setWithdrawAmount = domain.createEvent<number>(
  'on select amount to withdraw'
)

export const copyTopUpWallet = domain.createEvent<void>('on copy to clipboard')

export const getPaymentWalletFx = domain.createEffect('get payment wallet', {
  handler: api.fetchPaymentWallet,
})

export const withdrawRequestFx = domain.createEffect('withdraw request', {
  handler: api.fetchWithdraw,
})

export const getPaymentWallet = domain.createEffect('try to get payment wallet')
export const withdrawRequest = domain.createEffect('try to withdraw request')

export const passwordChangeEvent = domain.createEvent<string>(
  'on input password value'
)

export const oldPasswordChangeEvent = domain.createEvent<string>(
  'on input old password value'
)

export const confirmPasswordChangeEvent = domain.createEvent<string>(
  'on input confirm password value'
)

export const savePassword = domain.createEvent('try to save password')

export const savePasswordFx = domain.createEffect(
  'try to save password on server',
  {
    handler: api.savePassword,
  }
)
