import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
  shimmerBackground,
} from '@/5_shared/styles'
import { HeadedList } from './headed-list'
import { NavTab as NavTabUi, Icon, IconName } from '@/5_shared/ui'
import { SkeletonHeadedList } from './skeleton-headed-list'

export const RootDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  min-width: 250px;
`

export const RootMobile = styled.div``

export const HeadedListWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 12px;
  width: 100%;
  box-sizing: border-box;
`

export const ListHeader = styled.div`
  display: inline-block;
  margin-bottom: 24px;

  padding-left: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 40px;

  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;

  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ListItem = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 36px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: all ${defaultTransitionTiming.fastest}s ease;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  user-select: none;
  background-color: ${({ $isSelected }) =>
    $isSelected ? defaultColorPalette.darkPrimary900 : 'none'};
  color: ${defaultColorPalette.white};
`

export const ListItemSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-left: 36px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  cursor: pointer;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;

  ${shimmerBackground(
    defaultColorPalette.darkPrimary800,
    defaultColorPalette.darkPrimary900,
    defaultColorPalette.darkPrimary800
  )}
`

export const ListAmount = styled.div`
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  color: ${defaultColorPalette.white};
`

export const ListAmountSkeleton = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  background-color: ${defaultColorPalette.darkPrimary600};
`

export const GenresBlock = styled(HeadedList)`
  background-color: ${defaultColorPalette.darkPrimary700};

  ${ListHeader} {
    color: ${defaultColorPalette.white};
    background-color: ${defaultColorPalette.darkPrimary800};
  }

  ${ListItem} {
    &:hover {
      background-color: ${defaultColorPalette.darkPrimary800};
    }

    &:active {
      background-color: ${defaultColorPalette.darkPrimary900};
    }
  }

  ${ListAmount} {
    background-color: ${defaultColorPalette.darkPrimary600};
  }
`

export const GenresSkeletonHeadedList = styled(SkeletonHeadedList)`
  background-color: ${defaultColorPalette.darkPrimary700};

  ${ListHeader} {
    color: ${defaultColorPalette.white};
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const BrandsSkeletonHeadedList = styled(SkeletonHeadedList)`
  background-color: ${defaultColorPalette.darkSurfaceTertiary};

  ${ListHeader} {
    color: ${defaultColorPalette.white};
    background-color: ${defaultColorPalette.darkNeutrals300};
  }
`

export const BrandsBlock = styled(HeadedList)`
  background-color: ${defaultColorPalette.darkSurfaceTertiary};

  ${ListHeader} {
    color: ${defaultColorPalette.white};
    background-color: ${defaultColorPalette.darkNeutrals300};
  }

  ${ListItem} {
    &:hover {
      background-color: ${defaultColorPalette.darkPrimary800};
    }

    &:active {
      background-color: ${defaultColorPalette.darkPrimary900};
    }
  }

  ${ListAmount} {
    background-color: ${defaultColorPalette.darkNeutrals300};
  }
`

export const NavTabTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 28px;
  color: ${defaultColorPalette.white};
`

export const NavTab = styled(NavTabUi)`
  max-width: 340px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
`

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

export const SelectedLanguage = styled.div`
  color: ${defaultColorPalette.darkPrimary700};
  cursor: pointer;
  font-weight: 500;
  flex-grow: 1;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SelectedAmount = styled.div`
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  color: ${defaultColorPalette.white};
  background-color: ${defaultColorPalette.darkPrimary700};
`

export const ImageWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`

export const Dropdown = styled.div<{ $isShown: boolean }>`
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
  opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
  position: absolute;
  z-index: ${BASE_Z_INDEX.DROPDOWN};
  top: 40px;
  left: 5px;
  right: 5px;
  background-color: ${defaultColorPalette.darkPrimary700};
  border-radius: 6px;
  padding-bottom: 6px;
  padding-top: 6px;
  transition: opacity ${defaultTransitionTiming.faster}s ease;
`

export const Option = styled.div<{ $isSelected: boolean }>`
  color: ${defaultColorPalette.white};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  transition: background-color ${defaultTransitionTiming.fastest}s ease;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? defaultColorPalette.darkPrimary800
      : defaultColorPalette.darkPrimary700};

  &:hover {
    background-color: ${defaultColorPalette.darkPrimary800};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

const iconStyles = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const ChevronDownIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronDown,
})`
  pointer-events: none;
`

export const ChevronUpIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronUp,
})`
  pointer-events: none;
`
