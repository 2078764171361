import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
} from '@/5_shared/styles'
import { Icon, IconName } from '@/5_shared/ui'

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 140px;
`

export const SelectedLanguage = styled.div`
  color: white;
  cursor: pointer;
  font-weight: 500;
  flex-grow: 1;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ImageWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`

export const GlobeIcon = styled(Icon).attrs({
  name: IconName.Globe03,
  fill: defaultColorPalette.white,
})``

export const Dropdown = styled.div<{ $isShown: boolean }>`
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
  opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
  position: absolute;
  z-index: ${BASE_Z_INDEX.DROPDOWN};
  top: 40px;
  left: 5px;
  right: 5px;
  background-color: ${defaultColorPalette.darkPrimary700};
  border-radius: 6px;
  padding-bottom: 6px;
  padding-top: 6px;
  transition: opacity ${defaultTransitionTiming.faster}s ease;
`

export const Option = styled.div<{ $isSelected: boolean }>`
  color: ${defaultColorPalette.white};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  transition: background-color ${defaultTransitionTiming.fastest}s ease;
  user-select: none;

  background-color: ${({ $isSelected }) =>
    $isSelected
      ? defaultColorPalette.darkPrimary800
      : defaultColorPalette.darkPrimary700};

  &:hover {
    background-color: ${defaultColorPalette.white};
    color: ${defaultColorPalette.black};
  }

  &:active {
    background-color: ${defaultColorPalette.darkPrimary800};
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

const iconStyles = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const ChevronDownIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronDown,
})`
  pointer-events: none;
`

export const ChevronUpIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.ChevronUp,
})`
  pointer-events: none;
`
