import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'

import * as Styled from './styled'
import { useOutsideClick } from '@/5_shared/hooks'

type DropdownProps = {
  items: string[]
  selectedItem: string
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  ListStyleComponent?: React.ComponentType<{
    className?: string
    children: ReactNode
    $isOpen: boolean
  }>
  listClassName?: string
  ItemStyleComponent?: React.ComponentType<{
    children: ReactNode
    className?: string
    item: string
    onClick: (item: string) => void // Добавление обработчика клика
    $isSelected: boolean
  }>
  itemClassName?: string
  onClick: (item: string) => void // Обработчик клика на уровне Dropdown
  className?: string
}

export const Dropdown: FC<DropdownProps> = memo(
  ({
    items,
    ListStyleComponent = Styled.DefaultListStyleComponent,
    ItemStyleComponent = Styled.DefaultItemStyleComponent,
    onClick,
    selectedItem,
    isOpen: externalIsOpen,
    setIsOpen: externalSetIsOpen,
    className,
    listClassName,
    itemClassName,
  }: DropdownProps) => {
    const [internalIsOpen, setInternalIsOpen] = useState(false)
    const thisRef = useRef<HTMLDivElement>(null)

    const isOpen =
      externalIsOpen !== undefined ? externalIsOpen : internalIsOpen
    const setIsOpen = externalSetIsOpen || setInternalIsOpen

    const closeDropdown = useCallback(() => {
      setIsOpen(false)
    }, [setIsOpen])

    useOutsideClick(thisRef, isOpen, closeDropdown)

    return (
      <Styled.DropdownWrapper
        className={className}
        ref={thisRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Styled.DropdownButton>Dropdown</Styled.DropdownButton>

        <Styled.IconWrapper>
          {isOpen ? <Styled.ChevronUpIcon /> : <Styled.ChevronDownIcon />}
        </Styled.IconWrapper>

        <ListStyleComponent className={listClassName} $isOpen={isOpen}>
          {items.map((item, index) => (
            <ItemStyleComponent
              key={index}
              className={itemClassName}
              item={item}
              $isSelected={item === selectedItem}
              onClick={() => {
                closeDropdown()
                onClick(item)
              }}
            >
              {item}
            </ItemStyleComponent>
          ))}
        </ListStyleComponent>
      </Styled.DropdownWrapper>
    )
  }
)

Dropdown.displayName = 'Dropdown'
