import styled from 'styled-components'
import {
  BASE_Z_INDEX,
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxL,
} from '@/5_shared/styles'
import { Icon, IconName } from '@/5_shared/ui'

export const Root = styled.div`
  display: flex;
  gap: 0 26px;
  color: #fff;
  white-space: nowrap;
  position: relative;
  align-items: center;
  min-width: 200px;

  ${MediaMaxL`
    gap: 0 13px;
  `}
`

export const ContactsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`

export const Contact = styled.a`
  size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  color: ${defaultColorPalette.white};
  transition: all ${defaultTransitionTiming}s ease;
  text-decoration: none;
`

const iconStyles = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const MailIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.Mail01,
})``

export const TelegramIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.Telegram,
  viewBox: '5 5 25 25',
})``

export const SkypeIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.Phone,
})``

export const PhoneIcon = styled(Icon).attrs({
  ...iconStyles,
  name: IconName.Phone,
})``

export const Dropdown = styled.div<{ $isShown: boolean }>`
  visibility: ${({ $isShown }) => ($isShown ? 'visible' : 'hidden')};
  opacity: ${({ $isShown }) => ($isShown ? 1 : 0)};
  z-index: ${BASE_Z_INDEX.DROPDOWN};
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color: ${defaultColorPalette.darkPrimary700};
  border-radius: 6px;
  padding-bottom: 6px;
  padding-top: 6px;
  transition: opacity ${defaultTransitionTiming.faster}s ease;
`

export const ContactsRowDropdown = styled.div`
  color: ${defaultColorPalette.white};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  transition: background-color ${defaultTransitionTiming.fastest}s ease;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &:hover {
    background-color: ${defaultColorPalette.white};
    color: ${defaultColorPalette.black};
  }

  &:hover ${Contact} {
    color: ${defaultColorPalette.black};
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

const dropdownIconStyles = {
  width: 20,
  height: 20,
  color: defaultColorPalette.white,
}

export const ChevronDownIcon = styled(Icon).attrs({
  ...dropdownIconStyles,
  name: IconName.ChevronDown,
})`
  pointer-events: none;
`

export const ChevronUpIcon = styled(Icon).attrs({
  ...dropdownIconStyles,
  name: IconName.ChevronUp,
})`
  pointer-events: none;
`
