import { Currency } from '@/4_entities/currency'

export const enum ProfileModalTab {
  Profile = 'profile',
  Credits = 'credits',
  TopUp = 'top-up',
  Withdraw = 'withdraw',
}

// todo to user profile
export type UserWalletType = {
  currency: Currency
  amount: number
}[]

export type PaymentWallet = {
  timeout: number
  wallet_number: string
}

export type Time = {
  hours: number
  minutes: number
  seconds: number
}
