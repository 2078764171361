import styled from 'styled-components'
import { defaultColorPalette, MediaMaxS } from '@/5_shared/styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 72px;
`

export const Currencies = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -24px;

  ${MediaMaxS`
    gap: 16px;
  `}
`

export const CurrencyCardWrapper = styled.div`
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: calc(50% - 16px);
  `}
`

export const SelectCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  flex-basis: calc(33% - 24px);

  ${MediaMaxS`
    flex-basis: 100%;
  `}
`

export const SelectCurrencyTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: ${defaultColorPalette.darkTypeHigh};
`

export const SelectCurrencyText = styled.div`
  color: ${defaultColorPalette.darkTypeMedium};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
