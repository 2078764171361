import { combine, forward, guard, split } from 'effector'
import { domain } from './domain'
import {
  openDropdown,
  closeDropdown,
  dropdownClick,
  getBalancesFx,
  getBalances,
} from './events'
import { Balance, PaymentSystem } from '../types'
import { BalancesGate } from './gate'

export const $isProfileBlockOpened = domain
  .createStore<boolean>(false)
  .on(openDropdown, () => true)
  .reset(closeDropdown)

// effector split has horrible support in ts
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
split({
  source: dropdownClick,
  match: {
    open: $isProfileBlockOpened.map((val) => !val),
    close: $isProfileBlockOpened.map((val) => val),
  },
  cases: {
    open: openDropdown,
    close: closeDropdown,
  },
})

export const $fetchedBalancesList = domain
  .createStore<Balance[]>([])
  .on(getBalancesFx.doneData, (_, payload) => {
    return payload.balances.map((balance: Balance) => {
      return {
        ...balance,
        balance: (balance.credit / balance.money_2_credit_mul / 100).toFixed(2),
      }
    })
  })

export const $fetchedPaymentSystems = domain
  .createStore<PaymentSystem[]>([])
  .on(getBalancesFx.doneData, (_, payload) => payload.payment_systems)

forward({
  from: BalancesGate.open,
  to: getBalances,
})

guard({
  source: getBalances,
  filter: getBalancesFx.pending.map((pending) => !pending),
  target: getBalancesFx,
})

export const data = combine({
  Balances: $fetchedBalancesList,
  PaymentSystems: $fetchedPaymentSystems,
})
